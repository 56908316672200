// Utilities
import { computed, isRef } from "vue";
import { propsFactory, propValidator } from "@/fw/js/util";

// Types
import type { Ref } from "vue";

type RoundedValue = string | boolean;

const roundedOptions = [
  true,
  false,
  "0",
  "sm",
  "md",
  "lg",
  "xl",
  "circle",
];

export interface RoundedProps {
  rounded?: RoundedValue;
  tile?: boolean;
}

type RoundedData = {
  roundedClasses: Ref<string[]>;
};

// Composables
export const makeRoundedProps = propsFactory(
  {
    rounded: {
      type: String,
      default: "md",
      ...propValidator("rounded", roundedOptions),
    },
  },
  "rounded"
);

export function useRounded(
  props: RoundedProps | Ref<RoundedValue>
): RoundedData {
  const roundedClasses = computed(() => {
    let rounded = isRef(props) ? props.value : props.rounded;
    const classes: string[] = [];

    if (rounded == null) return classes;
    if (rounded === false) return classes;
    if (rounded === true) rounded = "sm";

    classes.push(`rounded-${rounded}`);

    return classes;
  });

  return { roundedClasses };
}
