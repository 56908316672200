import { EventBus } from "@/fw/js/util";
import { inject, reactive } from "vue";

export const options = reactive({});

/**
 * A unique identifier to access the provided/injected method
 */
export const SimpleDialogSymbol = Symbol();

/**
 * Composable which allows accessing the Simple Dialog service in Composition API
 * @returns Simple Dialog
 */
export function useSimpleDialog() {
  const SimpleDialog = inject(SimpleDialogSymbol);
  if (!SimpleDialog) {
    throw new Error("No Simple Dialog provided!");
  }

  return SimpleDialog;
}

// we need our modal component
export const FwSimpleDialogPlugin = {
  install: (app) => {
    const SimpleDialogService = {
      show: (_option) => {
        EventBus.$emit("show", _option);
      },

      hide: () => {
        EventBus.$emit("hide");
      },
    };

    app.config.globalProperties.$dialog = SimpleDialogService;
    if (typeof window !== "undefined") window.$dialog = SimpleDialogService;
    app.provide(SimpleDialogSymbol, SimpleDialogService);
  },
};
