<template>
  <div class="fw-tab--items">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
  },
  computed: {
    selected() {
      return this.modelValue;
    },
  },
});
</script>
