<template>
  <hr :style="styles" >
</template>

<script>
import { defineComponent, computed } from "vue";
import { convertToUnit } from "@/fw/js/util";
import { propValidator } from "@/fw/js/util";

export default defineComponent({
  name: "FwLine",

  props: {
    height: {
      type: [Number, String],
      default: 1,
    },
    type: {
      type: String,
      default: "solid",
      ...propValidator("type", ["solid", "dashed", "dotted"]),
    },
    color: {
      type: String,
      default: "border",
    },
  },

  emits: ["click"],

  setup(props) {
    const styles = computed(() => {
      return {
        border: `${(convertToUnit(props.height, "px"))} ${props.type} var(--fw-theme-${props.color})`,
      };
    });

    return {
      styles,
    };
  },
});
</script>
