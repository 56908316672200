<template>
  <fw-container class="home-footer-container pb-14 bg-black px-0" fluid>
    <fw-paper class="home-footer-wrapper mx-auto w-100" max-width="1177">
      <fw-row class="justify-space-between">
        <fw-col
          :class="infoWrapperPaddingLeft"
          class="home-footer-company-info-wrapper pos-relative pa-0"
          cols="auto"
        >
          <router-link :to="{ path: '/home' }">
            <fw-img
              class="home-footer-logo-img cursor-pointer"
              :src="require('@/assets/img/footer_logo.png')"
            />
          </router-link>
        </fw-col>

        <fw-col
          :class="menuWrapperSort"
          class="home-footer-menu-wrapper d-flex flex-wrap my-3 pl-5 pr-10 py-0"
          cols="auto"
        >
          <div class="d-flex mb-15 flex-wrap">
            <ul :class="menuListMarginRight" class="home-footer-menu-list">
              <li
                class="home-footer-menu-list-title t-color-font-contrast text-body-xl font-weight-large mb-4"
              >
                {{ $tt("$vuetify.footer_menu.list_products") }}
              </li>
              <fw-btn
                v-for="(product, i) in menuList.products.list"
                :key="i"
                class="size-normal home-footer-menu-list-element cursor-pointer d-block text-body-lg t-color-layer0 font-weight-regular t-color-layer0 bg-black border-none text-body-lg pa-0"
              >
                <a :href="product.path">
                  <span class="home-footer-navigate-btn t-color-layer0">
                    {{ $tt(`$vuetify.footer_menu.${product.nameKey}`) }}
                  </span>
                </a>
              </fw-btn>
            </ul>
            <ul :class="menuListMarginRight" class="home-footer-menu-list">
              <li
                class="size-normal home-footer-menu-list-title t-color-font-contrast text-body-xl font-weight-large mb-4"
              >
                {{ $tt("$vuetify.footer_menu.list_business") }}
              </li>
              <fw-btn
                v-for="(product, i) in menuList.business.list"
                :key="i"
                class="size-normal home-footer-menu-list-element cursor-pointer d-block text-body-lg t-color-layer0 t-color-layer0 bg-black border-none text-body-lg pa-0"
              >
                <router-link :to="`${product.path}`">
                  <span class="home-footer-navigate-btn t-color-layer0">
                    {{ $tt(`$vuetify.footer_menu.${product.nameKey}`) }}
                  </span>
                </router-link>
              </fw-btn>
            </ul>
          </div>

          <div class="d-flex flex-wrap">
            <ul :class="menuListMarginRight" class="home-footer-menu-list">
              <li
                class="home-footer-menu-list-title t-color-font-contrast text-body-xl font-weight-large mb-4"
              >
                {{ $tt("$vuetify.footer_menu.list_resource") }}
              </li>
              <fw-btn
                v-for="(product, i) in menuList.resource.list"
                :key="i"
                class="size-normal home-footer-menu-list-element cursor-pointer text-body-lg t-color-layer0 t-color-layer0 bg-black border-none text-body-lg pa-0"
              >
                <router-link
                  class="home-footer-navigate-btn t-color-layer0"
                  :to="{ path: '/why-buyglobal' }"
                >
                  {{ $tt(`$vuetify.footer_menu.${product.nameKey}`) }}
                </router-link>
              </fw-btn>
            </ul>
            <ul class="home-footer-menu-list mr-0">
              <li
                class="home-footer-menu-list-title t-color-font-contrast text-body-xl font-weight-large mb-4"
              >
                {{ $tt("$vuetify.footer_menu.list_support") }}
              </li>
              <fw-btn
                v-for="(product, i) in menuList.support.list"
                :key="i"
                class="size-normal home-footer-menu-list-element cursor-pointer t-color-layer0 t-color-layer0 bg-black border-none text-body-lg pa-0"
              >
                <router-link
                  class="home-footer-navigate-btn t-color-layer0"
                  :to="{ path: '/support' }"
                >
                  {{ $tt(`$vuetify.footer_menu.${product.nameKey}`) }}
                </router-link>
              </fw-btn>
            </ul>
          </div>
        </fw-col>

        <fw-row>
          <fw-paper
            max-width="600"
            :class="[copyrightPaddingLeft, copyrightPaddingTop]"
            class="home-footer-company-copyright font-weight-regular t-color-company-copyright pr-5"
          >
            Copyright © 2022. BuyGlobal. All rights reserved.
          </fw-paper>
        </fw-row>
      </fw-row>
    </fw-paper>
  </fw-container>
</template>

<script>
let obj_lang = {
  footer_menu: {
    list_products: {
      en: "Products",
      ko: "제품",
    },
    list_business: {
      en: "Business",
      ko: "비즈니스",
    },
    list_resource: {
      en: "Resource",
      ko: "리소스",
    },
    list_support: {
      en: "Support",
      ko: "지원",
    },
    blockchain_mainnet: {
      en: "Blockchain Mainnet",
      ko: "블록체인 메인넷",
    },
    wallet: {
      en: "Wallet",
      ko: "지갑",
    },
    exchange: {
      en: "Exchange",
      ko: "거래소",
    },
    transaction_monitoring: {
      en: "Transaction Monitoring",
      ko: "거래 모니터링",
    },
    game: {
      en: "Game",
      ko: "게임",
    },
    payment: {
      en: "Payment",
      ko: "결제",
    },
    business_defi: {
      en: "Defi",
      ko: "디파이",
    },
    business_payment: {
      en: "Payment",
      ko: "결제",
    },
    business_NFT: {
      en: "NFT",
      ko: "NFT",
    },
    business_game: {
      en: "Game",
      ko: "게임",
    },
    business_fintech: {
      en: "Fintech",
      ko: "핀테크",
    },
    resource_why_buyglobal: {
      en: "Why BuyGlobal?",
      ko: "왜 BuyGlobal인가?",
    },
    support_contact_us: {
      en: "Contact us",
      ko: "문의하기",
    },
  },
};

export default {
  name: "FooterMenu",

  beforeMount() {
    this.$fw.lang.add(obj_lang);
  },

  data() {
    return {
      menuList: {
        products: {
          list: [
            {
              nameKey: "blockchain_mainnet",
              path: "javascript:void(0)",
            },
            {
              nameKey: "wallet",
              path: "https://metarigo.io/#/landing",
            },
            {
              nameKey: "exchange",
              path: "https://ndealpay.io/#/",
            },
            {
              nameKey: "transaction_monitoring",
              path: "javascript:void(0)",
            },
            {
              nameKey: "game",
              path: "javascript:void(0)",
            },
            {
              nameKey: "payment",
              path: "javascript:void(0)",
            },
          ],
        },
        business: {
          list: [
            {
              nameKey: "business_defi",
              path: "/business/defi",
            },
            {
              nameKey: "business_payment",
              path: "/business/payment",
            },
            {
              nameKey: "business_NFT",
              path: "/business/nft-game",
            },
            {
              nameKey: "business_game",
              path: "/business/nft-game",
            },
            {
              nameKey: "business_fintech",
              path: "/business/fintech-software",
            },
          ],
        },
        resource: {
          list: [
            {
              nameKey: "resource_why_buyglobal",
            },
          ],
        },
        support: {
          list: [
            {
              nameKey: "support_contact_us",
            },
          ],
        },
      },
    };
  },
  computed: {
    infoWrapperPaddingLeft() {
      return this.$fw.display.mdAndDown ? "pl-5" : "";
    },
    menuWrapperSort() {
      return this.$fw.display.smAndDown ? "mr-auto" : "";
    },
    menuListMarginRight() {
      return this.$fw.display.smAndUp ? "menu-list-margin-right" : "mr-10";
    },
    copyrightPaddingLeft() {
      return this.$fw.display.mdAndDown ? "pl-5" : "";
    },
    copyrightPaddingTop() {
      return this.$fw.display.xs ? "pt-8" : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.home-footer-container {
  padding-top: 84px !important;
  padding-bottom: 88px !important;
}
.menu-list-margin-right {
  margin-right: 80px;
}
.home-footer-menu-list-element a {
  text-decoration: none;
  display: inline-block;
  height: 30px;
}
.home-footer-menu-list {
  margin: 0 82px 0 0;
  list-style: none;

  &-title {
    line-height: 18px;
  }

  &-element {
    line-height: 24px;
  }
}
</style>
