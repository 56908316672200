
import { defineComponent, computed, ref } from "vue";


import {
  makeBackgroundColorProps,
  useBackgroundColor,
} from "@/fw/composables/backgroundable";
import { makeBorderProps, useBorder } from "@/fw/composables/border";
import { makeDimensionProps, useDimension } from "@/fw/composables/dimensions";
import { makeShadowProps, useShadow } from "@/fw/composables/boxShadows";
import { makeRoundedProps, useRounded } from "@/fw/composables/rounded";
import { propValidator } from "@/fw/js/util";

import { FwBox, FwDropdownMenu, FwIcon } from "@/fw/components";

export default defineComponent({
  name: "FwSelect",
  components: {
    FwBox,
    FwDropdownMenu,
    FwIcon,
  },
  props: {
    modelValue: {},
    size: {
      type: String,
      ...propValidator("size", ["small", "normal", "large", "x-large"]),
    },
    optionHeight: {
      type: [String, Number],
      default: 120,
    },
    options: {},
    clickOnClose: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
    },
    rounded: {
      type: String, 
      default: "sm",
    },
    disabled: Boolean,
    ...makeBorderProps(),
    ...makeDimensionProps(),
    ...makeShadowProps(),
    ...makeBackgroundColorProps(),
  },

  emits: ["change", "click", "update:modelValue"],

  setup(props, { emit }) {
    const { borderClasses } = useBorder(props);
    const { dimensionStyles } = useDimension(props);
    const { shadowClasses } = useShadow(props);
    const { roundedClasses } = useRounded(props);
    const { backgroundClasses } = useBackgroundColor(props);

    const classes = computed(() => {
      return [
        borderClasses.value,
        shadowClasses.value,
        roundedClasses.value,
        backgroundClasses.value,
        props.size,
        dropdownIsOpen.value ? "fw-select--expanded" : "",
      ];
    });

    const styles = computed(() => {
      return [dimensionStyles.value];
    });

    let computedModelValue = computed({
      get() {
        return props.modelValue;
      },
      set(_value) {
        emit("update:modelValue", _value);
      },
    });

    function onClick() {
      emit("click");
    }

    function onClickOption(_value: any) {
      computedModelValue = _value;
      emit("update:modelValue", _value);
      emit("change", _value);
    }

    let dropdownIsOpen = ref(false);

    function dropdownOnChange(_is_open: boolean) {
      dropdownIsOpen.value = _is_open;
    }

    return {
      classes,
      styles,
      computedModelValue,
      onClick,
      onClickOption,
      dropdownIsOpen,
      dropdownOnChange,
    };
  },
});
