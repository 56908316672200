<template>
  <comp-app-bar />
  <router-view />
  <comp-footer-menu />
</template>

<script>
import { useFw } from "@/fw";
import { onMounted } from "vue";
import CompAppBar from "@/components/navigation/header/CompAppBar.vue";
import CompFooterMenu from "@/components/navigation/footer/CompFooterMenu.vue";
export default {
  components: { CompAppBar, CompFooterMenu },

  setup() {
    const { lang } = useFw();

    onMounted(() => {
      const selectedLanguage = localStorage.getItem("selectedLang") || "en";
      lang.locale_change(selectedLanguage);
    });
  },
};
</script>
<style lang="scss">
  .size-large {
    /* line-height: 4.18rem !important; */
  }
</style>
