
import { v4 as uuid } from "uuid";
import { defineComponent, ref } from "vue";
import { useExpansionPanelStore } from "./composables/vue-collapse-panel__store";

export default defineComponent({
  name: "FwExpansionPanel",
  props: {
    accordion: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const idGroup = ref(`group-${uuid()}`);
    const { setGroupAccordionStatus } = useExpansionPanelStore();
    setGroupAccordionStatus(idGroup.value, props.accordion);

    return {
      idGroup,
    };
  },
});
