import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "select" }
const _hoisted_2 = { class: "text-truncate" }
const _hoisted_3 = {
  key: 0,
  class: "t-color-secondary text-truncate"
}
const _hoisted_4 = { class: "arrow-icon-wrap" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fw_icon = _resolveComponent("fw-icon")!
  const _component_fw_box = _resolveComponent("fw-box")!
  const _component_fw_dropdown_menu = _resolveComponent("fw-dropdown-menu")!
  const _directive_ripple = _resolveDirective("ripple")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["fw-select pos-relative", _ctx.classes]),
    style: _normalizeStyle(_ctx.styles)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "prepend-inner"),
      _renderSlot(_ctx.$slots, "selected", { selected: _ctx.computedModelValue }, () => [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.computedModelValue), 1)
      ]),
      (_ctx.computedModelValue == '' || _ctx.computedModelValue == null)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.placeholder), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_fw_icon, {
        class: "arrow-icon",
        icon: "chevron-down"
      })
    ]),
    _createVNode(_component_fw_dropdown_menu, {
      onChange: _ctx.dropdownOnChange,
      "click-on-close": _ctx.clickOnClose
    }, {
      default: _withCtx(() => [
        _createVNode(_component_fw_box, {
          class: "option-box overflow-auto pa-0",
          "max-height": _ctx.optionHeight
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "options", {
              options: _ctx.options,
              onClick: _ctx.onClickOption
            }, () => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, key) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["option px-3 py-2", { selected: option == _ctx.computedModelValue }]),
                  key: key,
                  onClick: ($event: any) => (_ctx.onClickOption(option))
                }, [
                  _createTextVNode(_toDisplayString(option), 1)
                ], 10, _hoisted_5)), [
                  [_directive_ripple]
                ])
              }), 128))
            ])
          ]),
          _: 3
        }, 8, ["max-height"])
      ]),
      _: 3
    }, 8, ["onChange", "click-on-close"])
  ], 6)), [
    [_directive_ripple]
  ])
}