import { FwSnackbar, FwSnackbarPlugin } from "@/fw/components/snackbar";
import { FwSimpleDialog, FwSimpleDialogPlugin } from "@/fw/components/simple_dialog";
import * as FwComponents from "@/fw/components";

export default {
  install: (app) => {
    app.use(FwSnackbarPlugin);
    app.use(FwSimpleDialogPlugin);
    app.component("fw-snackbar", FwSnackbar);
    app.component("fw-simple-dialog", FwSimpleDialog);
    for (let name in FwComponents) {
      app.component(name, FwComponents[name]); 
    }
  },
};
