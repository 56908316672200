<template>
  <span class="fw-currency-rate">
    $ {{ value }}
  </span>
</template>

<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "FwCurrencyRate",

    props: {
      value: {
        type: String,
      }
    },
  });
</script>