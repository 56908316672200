<template>
  <teleport to="body">
    <section
      id="fw-snackbar--container"
      :class="[generatedBaseClasses]"
      class="fw-snackbar"
    >
      <transition-group name="fw-snackbar-message" tag="div">
        <SnackbarMessage
          v-for="message in messages"
          :key="message.id"
          :message="message"
          :message-class="messageClass"
          :color="message.type"
          :background="message.type"
          :dense="dense"
          :border-class="borderClass"
          @dismiss="remove($event, true)"
          />
          <!-- background="snackbar" -->
      </transition-group>
    </section>
  </teleport>
</template>

<script>
import { defineComponent, computed, onMounted, onUnmounted } from "vue";

import SnackbarMessage from "./components/__snackbar__item.vue";

import { messages } from "./service.js";
import { EventBus } from "@/fw/js/util";

export default defineComponent({
  components: {
    SnackbarMessage,
  },
  props: {
    top: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: [String, HTMLElement],
      default: "body",
    },
    border: {
      type: String,
      default: "",
      validator: (v) => ["top", "bottom", "left", "right", ""].includes(v),
    },
    duration: {
      type: Number,
      default: null,
    },
    messageClass: {
      type: String,
      default: "",
    },
    zIndex: {
      type: Number,
      default: 10000,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    groups: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: "snackbar",
    },
  },

  emits: ["added", "dismissed", "removed", "cleared"],

  setup(props, { emit }) {
    const generatedBaseClasses = computed(() => {
      return {
        "is-top": props.top,
        "is-bottom": props.top === false && props.bottom,
        "is-left": props.left,
        "is-right": props.left === false && props.right,
        "is-middle": props.top === false && props.bottom === false,
        "is-center": props.left === false && props.right === false,
        "has-shadow": props.shadow,
      };
    });

    const borderClass = computed(() =>
      props.border ? `border-${props.border}` : ""
    );

    const hashCode = (s) =>
      Math.abs(
        s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0)
      );

    let messageId = 1;

    onMounted(() => {
      EventBus.$on("add", (ev) => {
        emit("added", ev);
        if (!ev.group) {
          ev.group = hashCode(`${ev.type}${ev.title}${ev.text}`).toString(16);
        }
        // If there's a default duration and no message duration is set, use the default
        if (props.duration && !ev.duration && ev.duration !== 0) {
          ev.duration = props.duration;
        }
        // Find the existing message if one with the same group-key already exists
        const existingGroup = ev.group && messages.value.find((msg) => msg.group === ev.group);

        if (props.groups === false || !existingGroup) {
          const message = {
            ...ev,
            id: messageId,
            count: 1,
          };
          if (props.reverse){
            messages.value.unshift(message);
          } else {
            messages.value.push(message);
          } 
          messageId++;
        } else {
          existingGroup.count++;
        }
      });

      EventBus.$on("clear", () => {
        emit("cleared");
        messages.value = [];
      });
    });

    onUnmounted(() => {
      EventBus.$off("add");
      EventBus.$off("clear");
    });

    const remove = (ev, wasDismissed = false) => {
      if (wasDismissed) {
        emit("dismissed", ev)
      } else {
        emit("removed", ev);
      } 

      messages.value = messages.value.filter((message) => {
        return message.id !== ev.id;
      });
    };

    return {
      messages,
      generatedBaseClasses,
      borderClass,
      remove,
    };
  },
});
</script>

<style lang="scss">
@import "./fw__snackbar.scss";
</style>