<template>
  <fw-box
    class="fw-file-input d-flex justify-center align-center cursor-pointer bg-layer1 pa-4"
    :class="classes"
    :style="styles"
    @dragenter="event.dragenter"
    @dragover="event.dragover"
    @dragleave="event.dragleave"
    @drop="event.drop"
  >
    <div v-if="ui.form.file_input.files.length < 1">
        <span
          v-if="!ui.form.file_input.is_active"
          class="fw-file-input__message font-weight-medium text-title-xs t-color-secondary"
        >
          <slot name="drag_message">
            {{ $tt("$vuetify.file_input.drag_here") }}
          </slot>
        </span>

        <span
          v-else
          class="fw-file-input__message font-weight-medium text-title-xs"
          :class="`t-color-${activeColor}`"
        >
          {{ $tt("$vuetify.file_input.drop_here") }}
        </span>
    </div>

    <div v-else>
      <div v-if="!showImage" class="d-flex flex-wrap justify-center">
        <fw-card
          v-for="(file, key) in ui.form.file_input.files"
          :key="key"
          :border="activeColor"
          max-width="135"
          class="t-color-font text-truncate ma-2"
        >
          <fw-icon icon="file-outline" class="mr-2"></fw-icon>

          <span>
            {{ file.name }}
          </span>
        </fw-card>
      </div>

      <div v-else class="d-flex justify-center flex-wrap gap-10">
        <div
          v-for="(file, key) in ui.form.file_input.base64_storage"
          :key="key"
        >
          <fw-img
            v-if="checkFileTypeImage(file.type)"
            :src="file.base64"
            height="100%"
            width="100%"
            max-height="842"
            max-width="584"
          />

          <fw-card
            v-else
            :border="activeColor"
            max-width="135"
            class="t-color-font text-truncate ma-2"
          >
            <fw-icon icon="file-outline" class="mr-2"></fw-icon>

            <span>
              {{ file.name }}
            </span>
          </fw-card>
        </div>
      </div>
    </div>

    <fw-btn
      class="fw-file-input__button ml-auto"
      :color="activeColor"
      rounded="sm"
    >
      <span class="text-body-md">
        {{ $tt("$vuetify.file_input.upload") }}
      </span>
    </fw-btn>

    <div
      :class="
        ui.form.file_input.is_hover
          ? `fw-file-input__is_hover t-color-${activeColor}`
          : ''
      "
    />

    <input
      id="fw_file_input"
      class="fw-file-input__input cursor-pointer h-100 w-100 pa-0"
      type="file"
      :multiple="multiple"
      @change="event.change"
      @mouseover="event.mouseover"
      @mouseout="event.mouseout"
    />
  </fw-box>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  computed,
} from "vue";
import { useFw } from "@/fw";
import { FwCard, FwBtn, FwBox, FwIcon } from "@/fw/components";
import { makeDimensionProps, useDimension } from "@/fw/composables/dimensions";
import { makeShadowProps, useShadow } from "@/fw/composables/boxShadows";
import {
  makeBackgroundColorProps,
  useBackgroundColor,
} from "@/fw/composables/backgroundable";
import { makeRoundedProps, useRounded } from "@/fw/composables/rounded";
import { propValidator } from "@/fw/js/util";

let obj_lang = {
  file_input: {
    drag_here: {
      en: "Drag file here",
      ko: "파일을 이곳으로 드래그하세요",
    },
    drop_here: {
      en: "Drop!",
      ko: "놓으세요!",
    },
    upload: {
      en: "Upload",
      ko: "업로드",
    },
  },
};

export default defineComponent({
  name: "FwFileInput",

  components: {
    FwCard,
    FwBtn,
    FwBox,
    FwIcon,
  },

  props: {
    ...makeDimensionProps(),
    ...makeShadowProps(),
    ...makeBackgroundColorProps(),
    ...makeRoundedProps(),

    class: {
      type: String,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    activeColor: {
      type: String,
      default: "primary",
    },

    showImage: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      ...propValidator("size", ["small", "normal", "large", "x-large"]),
      default: "normal",
    },
  },

  emits: [
    "change:file", // (구) 파일 업로드시 base64를 인코딩 promise 객체로 반환 (해당 emit 사용중인 작업물 확인 후 삭제 예정)
    "upload", // 이미지를 인코딩한 base64값으로 반환
  ],

  setup(props, { emit }) {
    const { lang, func } = useFw();
    const { dimensionStyles } = useDimension(props);
    const { shadowClasses } = useShadow(props);
    const { backgroundClasses } = useBackgroundColor(props);
    const { roundedClasses } = useRounded(props);

    const state = reactive({
      ui: {
        form: {
          file_input: {
            files: [],
            is_active: false,
            is_hover: false,
            base64_storage: [],
          },
        },
      },
    });

    const classes = computed(() => {
      return [
        backgroundClasses.value,
        shadowClasses.value,
        roundedClasses.value,
        props.class,
        props.size,
        state.ui.form.file_input.is_active
          ? `fw-file-input__active t-color-${props.activeColor}`
          : "",
      ];
    });

    const styles = computed(() => {
      return [
        dimensionStyles.value,
      ];
    });

    const api = {
      clear: () => {
        state.ui.form.file_input.files = [];
        state.ui.form.file_input.base64_storage = [];
      },
    };

    const event = {
      change: (_event) => {
        state.ui.form.file_input.files = [];
        Object.values(_event.target.files).map((_file) => {
          _file.base64 = func.util.img.encode_base64(_file);
          state.ui.form.file_input.files.push(_file);
        });

        emit("change:file", state.ui.form.file_input.files);
        inputStatus.removeActive();

        base64Storage.listSet();
      },

      dragenter: () => {
        inputStatus.setActive();
      },

      dragover: () => {
        inputStatus.setActive();
      },

      dragleave: () => {
        inputStatus.removeActive();
      },

      drop: () => {
        inputStatus.removeActive();
      },

      mouseover: () => {
        inputStatus.setHover();
      },

      mouseout: () => {
        inputStatus.removeHover();
      },
    };

    const base64Storage = {
      listSet: () => {
        state.ui.form.file_input.base64_storage = [];

        state.ui.form.file_input.files.map((_file) => {
          _file.base64
            .then((file_base64) => {
              let file_info = {
                name: _file.name,
                type: _file.type,
                base64: file_base64,
              };
              state.ui.form.file_input.base64_storage.push(file_info);
            })
            .catch((_err) => {
              console.error(_err);
            });
        });

        if(state.ui.form.file_input.files.length > 0){
          emit("upload", state.ui.form.file_input.files);
        }
      },
    };

    const checkFileTypeImage = (_file) => {
      return _file && _file.split("/")[0] === "image";
    };

    const inputStatus = {
      setActive : () => {
        if (!state.ui.form.file_input.is_active) {
          state.ui.form.file_input.is_active = true;
        }
      },

      removeActive: () => {
        if (state.ui.form.file_input.is_active) {
          state.ui.form.file_input.is_active = false;
        }
      },

      setHover: () => {
        if (!state.ui.form.file_input.is_hover) {
          state.ui.form.file_input.is_hover = true;
        }
      },

      removeHover: () => {
        if (state.ui.form.file_input.is_hover) {
          state.ui.form.file_input.is_hover = false;
        }
      },
    }

    onBeforeMount(() => {
      lang.add(obj_lang);
    });

    return {
      ...toRefs(state),
      classes,
      styles,
      api,
      event,
      checkFileTypeImage,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "./fw__file_input.scss";
</style>
