
import { defineComponent, PropType } from "vue";
import { Doughnut } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, Plugin } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default defineComponent({
  name: "CompDoughnutChart",
  components: {
    Doughnut,
  },
  props: {
    chartValue: {
      type: Array as PropType<Array<number>>,
      default: () => [],
    },

    labels: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },

    label: {
      type: String,
      default: "Data",
    },

    labelPosition: {
      type: String,
      default: "top",
    },

    hideLabel: {
      type: Boolean,
      default: false,
    },

    labelAlign: {
      type: String,
      default: "center",
    },

    lineColor: {
      type: String,
      default: "#000000",
    },

    backgroundColors: {
      type: Array as PropType<Array<string>>,
      default: () => ["#16556f", "#75bbc8", "#c2c7ce"],
    },

    halfDoughnut: {
      type: Boolean,
      default: false,
    },

    cutout: {
      type: [String, Number],
      default: "50%",
    },

    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object as PropType<Partial<CSSStyleDeclaration>>,
      default: () => {}, // eslint-disable-line
    },
    plugins: {
      type: Array as PropType<Plugin<"doughnut">[]>,
      default: () => [],
    },
  },

  computed: {
    chartData() {
      return {
        labels: this.labelsGenerator,
        datasets: [
          {
            backgroundColor: this.backgroundColors,
            data: this.chartValue,
            borderWidth: 0,
          },
        ],
      };
    },

    labelsGenerator() {
      let labels = [];

      if (this.labels.length == 0) {
        for (let i = 0; i < this.chartValue.length; ++i) {
          labels.push("Data-" + (i + 1));
        }
      } else {
        labels = this.labels;
      }
      return labels;
    },

    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: !this.hideLabel,
            position: this.labelPosition,
            align: this.labelAlign,
          },
        },
        animation: {
          duration: 0,
        },
        cutout: this.cutoutPercent,
        circumference: this.halfDoughnut ? 180 : undefined,
        rotation: this.halfDoughnut ? -90 : undefined,
      };
    },

    cutoutPercent() {
      let cutout = this.cutout;

      if (cutout.toString().indexOf("%") == -1) {
        cutout = cutout + "%";
      }

      return cutout;
    },
  },
});
