import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e0ac80e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fw-datepicker-input d-flex" }
const _hoisted_2 = { class: "px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fw_icon = _resolveComponent("fw-icon")!
  const _component_fw_input = _resolveComponent("fw-input")!
  const _component_fw_datepicker = _resolveComponent("fw-datepicker")!

  return (_openBlock(), _createBlock(_component_fw_datepicker, {
    modelValue: _ctx.computedModelValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedModelValue) = $event)),
    trigger: "",
    range: _ctx.range,
    disabled: _ctx.disabled,
    "until-today": _ctx.untilToday,
    "default-period": _ctx.defaultPeriod,
    color: _ctx.color
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_fw_input, {
          ref: "fw_datepicker_input",
          modelValue: _ctx.inputValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
          readonly: "",
          standalone: "",
          disabled: _ctx.disabled,
          border: _ctx.border,
          shadow: _ctx.shadow,
          rounded: _ctx.rounded,
          background: _ctx.background,
          onClick: _ctx.onClickDatePickerInput
        }, {
          "prepend-inner": _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_fw_icon, {
                icon: "calendar-blank",
                size: "20",
                color: "desc"
              })
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "disabled", "border", "shadow", "rounded", "background", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "range", "disabled", "until-today", "default-period", "color"]))
}