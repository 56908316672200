const example_module = {
  state: () => ({
    //
  }),
  mutations: {
    //
  },
  getters: {
    //
  },
  actions: {
    //
  },
};

export default example_module;
