<template>
  <fw-paper
    :height="height"
    background="layer0"
    class="fw-progress pos-relative"
    :class="trackClasses"
  >
    <fw-paper
      class="fw-progress-bar"
      :class="classes"
      height="100%"
      :width="progressBarWidth"
    ></fw-paper>

    <div 
      v-if="slotsExist" 
      class="fw-progress-bar__slot-container pos-absolute" 
    >
      <div>
        <slot :data="progressBarWidth">
        </slot>
      </div>
    </div>
  </fw-paper>
</template>

<script>
import { makeRoundedProps, useRounded } from "@/fw/composables/rounded";
import { defineComponent, reactive, toRefs, computed } from "vue";


export default defineComponent({
  name: "FwProgressBar",

  components: {
    //
  },

  props: {
    value: {
      type: Number,
      default: 0,
    },

    totalStep: {
      type: Number,
      default: 100,
    },

    color: {
      type: String,
      default: "primary",
    },

    height: {
      type: [Number, String],
      default: 8,
    },

    reverse: {
      type: Boolean,
      default: false,
    },

    striped: {
      type: Boolean,
      default: false,
    },

    ...makeRoundedProps(),
  },

  setup(props, {slots}) {
    const { roundedClasses } = useRounded(props);

    const state = reactive({
      //
    });

    const classes = computed(() => {
      return [
        `bg-${props.color}`,
        props.reverse ? "ml-auto" : "",
        roundedClasses.value,
      ];
    });

    const trackClasses = computed(() => {
      return [
        roundedClasses.value,
        props.striped ? "fw-progress-striped active" : "",
      ];
    });

    const progressBarWidth = computed(() => {
      if (!props.totalStep) {
        return "0%";
      }

      if (props.value > props.totalStep) {
        return "100%";
      }

      return `${(props.value / props.totalStep) * 100}%`;
    });

    const slotsExist = computed(() => {
      return slots.default;
    })

    return {
      ...toRefs(state),
      progressBarWidth,
      classes,
      trackClasses,
      slotsExist,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "./fw__progress_bar.scss";
</style>
