
import { defineComponent } from "vue";

// Compatibility delta due to rounding issues
const delta = 2.5;

interface FwHorizontalData {
  left: number;
  width: number;
  scrollWidth: number;

  hasPrev: boolean;
  hasNext: boolean;

  debounceId?: number | undefined | ReturnType<typeof setTimeout>;
}

export default defineComponent({
  name: "FwHorizontal",
  data(): FwHorizontalData {
    return {
      left: 0,
      width: 0,
      scrollWidth: 0,

      hasPrev: false,
      hasNext: false,

      debounceId: undefined,
    };
  },
  props: {
    /**
     * Navigation button visibility
     */
    button: {
      type: Boolean,
      default: () => true,
    },
    /**
     * Navigation button alignment, default to between the edge of the horizontal axis.
     */
    buttonBetween: {
      type: Boolean,
      default: () => true,
    },
    /**
     * Scrollbar visibility
     */
    scroll: {
      type: Boolean,
      default: () => false,
    },
    /**
     * Use default responsive breakpoint.
     */
    responsive: {
      type: Boolean,
      default: () => false,
    },
    /**
     * Move window, indicates the percent of width to travel when nav is triggered.
     */
    displacement: {
      type: Number,
      default: () => 1.0,
    },
    /**
     * Snap to start|center|end
     */
    snap: {
      type: String,
      default: () => "start",
    },
  },
  mounted() {
    this.onScrollDebounce();
  },
  beforeUnmount() {
    clearTimeout(this.debounceId);
  },
  methods: {
    children(): HTMLCollection {
      const container = this.$refs.container as Element;
      return container.children;
    },
    findPrevSlot(x: number): Element | undefined {
      const children = this.children();

      for (let i = 0; i < children.length; i++) {
        const rect = children[i].getBoundingClientRect();

        if (rect.left <= x && x <= rect.right) {
          return children[i];
        }

        if (x <= rect.left) {
          return children[i];
        }
      }
    },
    findNextSlot(x: number): Element | undefined {
      const children = this.children();

      for (let i = 0; i < children.length; i++) {
        const rect = children[i].getBoundingClientRect();

        if (rect.right <= x) {
          continue;
        } else if (rect.left <= x) {
          return children[i];
        }

        if (x <= rect.left) {
          return children[i];
        }
      }
    },
    /**
     * Toggle and scroll to the previous set of horizontal content.
     */
    prev(): void {
      this.$emit("prev");

      const container = this.$refs.container as Element;
      const left = container.getBoundingClientRect().left;
      const x = left + container.clientWidth * -this.displacement - delta;
      const element = this.findPrevSlot(x);

      if (element) {
        const width = element.getBoundingClientRect().left - left;
        this.scrollToLeft(container.scrollLeft + width);
        return;
      }

      const width = container.clientWidth * this.displacement;
      this.scrollToLeft(container.scrollLeft - width);
    },
    /**
     * Toggle and scroll to the next set of horizontal content.
     */
    next(): void {
      this.$emit("next");

      const container = this.$refs.container as Element;
      const left = container.getBoundingClientRect().left;
      const x = left + container.clientWidth * this.displacement + delta;
      const element = this.findNextSlot(x);

      if (element) {
        const width = element.getBoundingClientRect().left - left;
        if (width > delta) {
          this.scrollToLeft(container.scrollLeft + width);
          return;
        }
      }

      const width = container.clientWidth * this.displacement;
      this.scrollToLeft(container.scrollLeft + width);
    },
    /**
     * Index of the slots to scroll to.
     * @param {number} i index
     */
    scrollToIndex(i: number): void {
      const children = this.children();

      if (children[i]) {
        const container = this.$refs.container as Element;
        const rect = children[i].getBoundingClientRect();

        const left = rect.left - container.getBoundingClientRect().left;
        this.scrollToLeft(container.scrollLeft + left);
      }
    },
    /**
     * Amount of pixel to scroll to on the left.
     * @param {number} left of the horizontal
     * @param {'smooth' | 'auto} [behavior='smooth']
     */
    scrollToLeft(left: number, behavior: "smooth" | "auto" = "smooth"): void {
      const element = this.$refs.container as Element;
      element.scrollTo({ left: left, behavior: behavior });
    },
    onScroll(): void {
      const container = this.$refs.container as Element;

      // Resolves https://github.com/fuxingloh/fw-slide/issues/99#issue-862691647
      if (!container) return;

      this.$emit("scroll", {
        left: container.scrollLeft,
      });

      clearTimeout(this.debounceId);
      // eslint-disable-next-line
      this.debounceId = setTimeout(this.onScrollDebounce, 100);
    },
    onScrollDebounce(): void {
      this.refresh((data) => {
        this.$emit("scroll-debounce", data);
      });
    },
    /**
     * Manually refresh fw-slide
     * @param {(data: FwHorizontalData) => void} [callback] after refreshed, optional
     */
    refresh(callback?: (data: FwHorizontalData) => void): void {
      this.$nextTick(() => {
        const data = this.calculate();

        this.left = data.left;
        this.width = data.width;
        this.scrollWidth = data.scrollWidth;
        this.hasNext = data.hasNext;
        this.hasPrev = data.hasPrev;

        callback?.(data);
      });
    },
    calculate(): FwHorizontalData {
      const container = this.$refs.container as Element;
      const firstChild = this.children()[0];

      function hasNext(): boolean {
        return container.scrollWidth > container.scrollLeft + container.clientWidth + delta;
      }

      function hasPrev(): boolean {
        if (container.scrollLeft === 0) {
          return false;
        }

        const containerVWLeft = container.getBoundingClientRect().left;
        const firstChildLeft = firstChild?.getBoundingClientRect()?.left ?? 0;
        return Math.abs(containerVWLeft - firstChildLeft) >= delta;
      }

      return {
        left: container.scrollLeft,
        width: container.clientWidth,
        scrollWidth: container.scrollWidth,
        hasNext: hasNext(),
        hasPrev: hasPrev(),
      };
    },
  },
});
