<template>
  <fw-paper>
    <fw-overlay
      v-if="$fw.display.mdAndDown"
      v-model="isButtonActive"
    ></fw-overlay>

    <fw-paper class="home-appbar-container pos-fixed bg-layer0 w-100 mx-auto">
      <fw-row align="center" class="home-appbar-wrapper mx-auto">
        <fw-col
          :class="[mainlogoPaddingLeft, mainlogoPaddingRight]"
          cols="auto "
          class="pl-0 py-4"
        >
          <router-link to="/home">
            <fw-img
              class="home-appbar-mainlogo d-flex cursor-pointer"
              :src="require('@/assets/img/appbar_mainLogo.png')"
              object-fit="fill"
            />
          </router-link>
        </fw-col>

        <fw-col
          v-for="buttons in mobileRouteButtons"
          :key="buttons"
          class="home-appbar-navigate-btn cursor-pointer px-0"
          cols="auto text-center"
        >
          <router-link :to="{ path: buttons.path }">
            <fw-btn
              v-if="$fw.display.lgAndUp"
              class="size-large bg-layer1 border-layer1 font-weight-large text-body-xl"
            >
              {{ $tt(`$vuetify.${buttons.nameKey}`) }}
            </fw-btn>
          </router-link>
        </fw-col>

        <fw-col cols="auto" class="px-0">
          <router-link :to="{ path: '/why-buyglobal' }">
            <fw-btn
              v-if="$fw.display.lgAndUp"
              class="size-large bg-layer1 border-layer1 font-weight-large text-body-xl"
            >
              {{ $tt("$vuetify.appbar_navigate.why_buyglobal") }}
            </fw-btn>
          </router-link>
        </fw-col>

        <fw-col cols="auto" class="px-0">
          <router-link :to="{ path: '/support' }">
            <fw-btn
              v-if="$fw.display.lgAndUp"
              class="size-large bg-layer1 border-layer1 font-weight-large text-body-xl"
            >
              {{ $tt("$vuetify.appbar_navigate.contact") }}
            </fw-btn>
          </router-link>
        </fw-col>

        <fw-col
          v-if="$fw.display.lgAndUp"
          cols="auto"
          class="ml-auto align-center d-flex pr-0"
        >
          <fw-img
            max-width="24"
            max-height="24"
            :src="require('@/assets/img/appbar_language_icon.png')"
            class="cursor-pointer mr-2"
            @click="toggleModal"
          ></fw-img>
          <div class="pr-5">
            <span class="cursor-pointer text-body-xl" @click="toggleModal">
              {{ currentLanguage }}
            </span>
          </div>
        </fw-col>
        <comp-change-language :showModal="showModal" :close-on-back="false" />

        <!-- 모바일 앱바 -->
        <fw-col
          v-if="$fw.display.mdAndDown"
          cols="auto"
          class="ml-auto pl-0 pr-2"
        >
          <div>
            <fw-btn class="cursor-pointer bg-layer0 border-none px-3">
              <div class="d-flex align-center">
                <fw-img
                  max-height="24"
                  :src="require('@/assets/img/appbar_language_icon.png')"
                  @click="toggleModal"
                />
              </div>
            </fw-btn>
            <fw-btn
              @click="toggleButtonActive"
              class="dropdownBtn cursor-pointer bg-layer0 border-none px-3"
            >
              <div class="d-flex align-center">
                <fw-img :src="require(`@/assets/img/${buttonImg}`)" />
              </div>
            </fw-btn>
          </div>
        </fw-col>
      </fw-row>

      <div
        v-if="isButtonActive && $fw.display.mdAndDown"
        class="home-appbar-hamberger-navigate-btn-wrapper pos-absolute"
      >
        <div v-for="buttons in mobileRouteButtons" :key="buttons">
          <router-link :to="{ path: buttons.path }">
            <transition name="fade" appear mode="in-out">
              <fw-btn
                class="home-appbar-hamberger-navigate-btn text-left pos-relative border-layer1 font-weight-large text-body-xl bg-layer1 w-100 pl-6"
              >
                {{ $tt(`$vuetify.${buttons.nameKey}`) }}
              </fw-btn>
            </transition>
          </router-link>
        </div>

        <div v-for="buttons in mobileDetailRouteButtons" :key="buttons">
          <router-link :to="`${buttons.path}`">
            <transition name="fade" appear mode="in-out">
              <fw-btn
                class="home-appbar-hamberger-detail-navigate-btn text-left pos-relative border-layer1 font-weight-large text-body-xl w-100 pl-10"
              >
                {{ $tt(`$vuetify.mobile_route_buttons.${buttons.nameKey}`) }}
              </fw-btn>
            </transition>
          </router-link>
        </div>

        <router-link :to="{ path: '/why-buyglobal' }">
          <transition name="fade" appear mode="in-out">
            <fw-btn
              class="home-appbar-hamberger-navigate-btn text-left pos-relative border-layer1 font-weight-large text-body-xl bg-layer1 w-100 pl-6"
            >
              {{ $tt("$vuetify.appbar_navigate.why_buyglobal") }}
            </fw-btn>
          </transition>
        </router-link>

        <router-link :to="{ path: '/support' }">
          <transition name="fade" appear mode="in-out">
            <fw-btn
              class="home-appbar-hamberger-navigate-btn text-left pos-relative border-layer1 font-weight-large text-body-xl bg-layer1 w-100 pl-6"
            >
              {{ $tt("$vuetify.appbar_navigate.support") }}
            </fw-btn>
          </transition>
        </router-link>
      </div>
    </fw-paper>
  </fw-paper>
</template>

<script>
let obj_lang = {
  appbar_navigate: {
    why_buyglobal: {
      en: "Why BuyGlobal?",
      ko: "왜 BuyGlobal인가?",
    },
    contact: {
      en: "Contact us",
      ko: "문의하기",
    },
    support: {
      en: "Support",
      ko: "지원",
    },
    language: {
      en: "Language",
      ko: "언어",
    },
  },
  mobile_route_buttons: {
    home: {
      en: "Home",
      ko: "홈",
    },
    products: {
      en: "Products",
      ko: "제품",
    },
    business: {
      en: "Business",
      ko: "비즈니스",
    },
    mobile_detail_route_buttons: {
      defi: {
        en: "Defi",
        ko: "디파이",
      },
      fintech_software: {
        en: "Fintech Software",
        ko: "핀테크 소프트웨어",
      },
      nft_game: {
        en: "NFT & Game",
        ko: "NFT & 게임",
      },
      exchange: {
        en: "Exchange",
        ko: "거래소",
      },
      payment: {
        en: "Payment",
        ko: "결제",
      },
      wallet: {
        en: "Wallet",
        ko: "지갑",
      },
    },
  },
};

import { obj_lang__data } from "@/fw_config/i18n";
import CompChangeLanguage from "@/components/common/popup_language/CompPopupChangeLanguage.vue";
export default {
  name: "AppBar",

  components: {
    CompChangeLanguage,
  },

  beforeMount() {
    this.$fw.lang.add(obj_lang);
  },

  created() {
    this.setVH();
    window.addEventListener("resize", this.setVH);
  },

  data() {
    return {
      showModal: false,
      isButtonActive: false,
      mobileRouteButtons: [
        {
          nameKey: "mobile_route_buttons.home",
          path: "/home",
        },
        {
          nameKey: "mobile_route_buttons.products",
          path: "/products",
        },
        {
          nameKey: "mobile_route_buttons.business",
          path: "/business",
        },
      ],
      mobileDetailRouteButtons: [
        {
          nameKey: "mobile_detail_route_buttons.defi",
          path: "/business/defi",
        },
        {
          nameKey: "mobile_detail_route_buttons.fintech_software",
          path: "/business/fintech-software",
        },
        {
          nameKey: "mobile_detail_route_buttons.nft_game",
          path: "/business/nft-game",
        },
        {
          nameKey: "mobile_detail_route_buttons.exchange",
          path: "/business/exchange",
        },
        {
          nameKey: "mobile_detail_route_buttons.payment",
          path: "/business/payment",
        },
        {
          nameKey: "mobile_detail_route_buttons.wallet",
          path: "/business/wallet",
        },
      ],
    };
  },

  methods: {
    setVH() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },

    toggleModal() {
      this.showModal = !this.showModal;
    },
    scrollToContactUs() {
      document
        .getElementById("contact-us")
        .scrollIntoView({ behavior: "smooth" });
    },
    scrollToForte() {
      document
        .getElementById("home-forte")
        .scrollIntoView({ behavior: "smooth" });
    },
    toggleButtonActive() {
      this.isButtonActive = !this.isButtonActive;
    },
    setScrollPrevent() {
      const elHtml = document.getElementsByTagName("html")[0];
      elHtml.classList.add("overflow-hidden");
    },
    clearScrollPrevent() {
      const elHtml = document.getElementsByTagName("html")[0];
      elHtml.classList.remove("overflow-hidden");
    },
  },

  computed: {
    currentLanguage() {
      const currentLangKey = this.$fw.lang.locale_current();
      const currentLang = obj_lang__data.language_list.find(
        (language) => language.key === currentLangKey
      );
      return currentLang ? currentLang.name : "";
    },
    mainlogoPaddingRight() {
      return this.$fw.display.mdAndDown ? "" : "pr-12";
    },
    mainlogoPaddingLeft() {
      return this.$fw.display.lgAndDown ? "pl-5" : "pl-8";
    },
    buttonImg() {
      return this.isButtonActive
        ? "appbar_hamberger_clicked.png"
        : "appbar_hamberger_default.png";
    },
  },
  mounted() {
    document.addEventListener("click", (event) => {
      if (!event.target.closest(".dropdownBtn")) {
        this.isButtonActive = false;
      }
    });
  },

  watch: {
    isButtonActive: function () {
      if (this.isButtonActive) {
        this.setScrollPrevent();
      } else {
        this.clearScrollPrevent();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-appbar-wrapper {
  max-width: 1728px;
}
.home-appbar-hamberger-navigate-btn-wrapper {
  height: calc(var(--vh, 1vh) * 100 - 72px);
  overflow-y: scroll;
}
.background {
  background: var(--fw-theme-business-defi-main-background);
  z-index: 9;
}
.home-appbar {
  &-container {
    z-index: 10;
  }
  &-dropdown-menu-container * {
    z-index: 5;
  }
  &-hamberger-navigate-btn-wrapper {
    z-index: 10;
    width: 100vw;
    top: 72px;
  }
  &-hamberger-navigate-btn {
    z-index: 5;
    height: 68px;
    border-radius: 0px !important;
  }
  &-hamberger-detail-navigate-btn {
    z-index: 5;
    height: 68px;
    border: none !important;
    border-radius: 0px !important;
    background-color: var(--fw-theme-border) !important;
  }
}
.router-link-active button {
  color: var(--fw-theme-primary);
}
</style>
