
    import { defineComponent, onMounted, ref, reactive, toRefs, computed } from 'vue';
    import { Form } from "vee-validate";

    export default defineComponent({
        components: {
            Form,
        },

        props: {
            modelValue: {},
            class: {
                type: String,
            },
            fillHeight: {
                type: Boolean,
                default: false,
            }
        },

        emits: ["valid", "submit-form", "change", "update:modelValue"],

        setup(props, { emit }){
            const fw_form = ref();

            const formState = reactive({
                values: {},
                isValid: false,
                isTouched: false,
            });

            const inheritClass = computed(() => props.class);

            // ==========================================
            // 사용함수
            const clear = () => {
                if(formState.isTouched){
                    const plainValue = {...formState.values} as any;
                    const resetValue = {} as any;
                    for (let key in plainValue) {
                        let value = plainValue[key];

                        switch(typeof value){
                            case "string":
                                resetValue[key] = "";
                                break;
                            case "boolean":
                                resetValue[key] = false;
                                break;
                            case "number":
                                resetValue[key] = 0;
                                break;
                            default:
                                break;
                        }
                    }
                    fw_form.value.setValues(resetValue);
                    fw_form.value.setErrors({});
                }
            }

            const validate = () => {
                if(formState.isTouched){
                    fw_form.value.validate(); 
                }
            }
            
            const reset = () => {
                fw_form.value.setErrors({});
            }

            // ==========================================
            // 이벤트
            const onChange = (_event: Event) => {
                emit("valid", true);
                emit("change", _event);
            };

            const onSubmit = (_event: object) => {
                emit("valid", true);
                emit("submit-form", _event);
            }
            
            const onInvalidSubmit = () => {
                emit("valid", false);
            }
            
            const onChangeInnerForm = (_values: object, _meta: {valid: boolean}) => {
                if(!formState.isTouched){
                    formState.isTouched = true;
                }
                formState.values = _values;
                formState.isValid = _meta.valid as boolean;
                
                emit("update:modelValue", formState.isValid);
            }

            onMounted(() => {
                fw_form.value;
            })

            // watch(
            //     () => formState.isValid,
            //     () => emit("valid", formState.isValid)
            // )

            return {
                fw_form,
                Form,
                inheritClass,

                ...toRefs(formState),

                onSubmit,
                onChange,
                onInvalidSubmit,
                onChangeInnerForm,
                clear,
                validate,
                reset,
            }
        }
    })
