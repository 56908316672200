export const theme = {
  light: {
    primary: "#007afa",
    secondary: "#999",
    info: "#007bff",
    success: "#28a745",
    error: "#f6465d",
    warning: "#f5b11c",
    disabled: "#afafaf",
    layer0: "#fff",
    layer1: "#FEFEFE",
    layer2: "#F9FAFC",
    layer3: "#9E9E9E",
    layer4: "#f6f7f8",
    border: "#F1F7FE",
    black: "#18171C",
    "border-lighter": "#F8F8F8",
    font: "#212431",
    "font-sub": "#fff",
    "font-contrast": "#6b7684",
    "font-appbar": "#444e5a",
    "font-policy": "#575F6A",
    "company-copyright": "#3e4249",
    "trade-rise": "#f6465d",
    "trade-sell": "#0ecb81",
    "trade-fall": "#0ecb81",
    form: "transparent",
    button: "#eef0f4",
    snackbar: "#1f1f1f",
    "guideline-content": "rgb(102, 105, 110);",
    "products-background": "linear-gradient(180deg, #2141E8 0%, #ABD7FF 100%);",
    "products-box-shadow":"rgba(0, 0, 0, 0.25)",
    "products-card-title-color": "#171D33",
    "business-main-background": "rgba(21, 38, 99, 0.6)",
    "business-defi-main-background": "rgba(0, 0, 0, 0.12)",
    "business-defi-main-description": "rgb(78, 87, 101)",
    "business-defi-detail-color": "rgb(102, 106, 110)",
    "card-border-color": "#CED2D6",
    "contect-us-background": "linear-gradient(270deg, #1F96FE 0%, #6CF2FB 100%);",
    "detail-background-blur": "rgba(0, 0, 0, 0.7)",
    "support-input-border": "#BEC3CA"
  },
  dark: {
    primary: "#096EFE",
    secondary: "#909498",
    info: "#007bff",
    success: "#28a745",
    error: "#f6465d",
    warning: "#f5b11c",
    disabled: "#afafaf",
    layer0: "#000000",
    layer1: "#18171c",
    layer2: "#2d3341",
    layer3: "#3d4355",
    layer4: "#f6f7f8",
    border: "#32363E",
    "border-lighter": "#32363E",
    font: "#fff",
    "font-sub": "#fff",
    "font-contrast": "#444e5a",
    "trade-buy": "#f6465d",
    "trade-rise": "#f6465d",
    "trade-sell": "#0ecb81",
    "trade-fall": "#0ecb81",
    form: "transparent",
    button: "#eef0f4",
    snackbar: "#1f1f1f",
  },
};
