<template>
  <div class="fw-theme-wrap" :class="theme.themeClass.value">
    <div class="fw-theme-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { makeThemeProps, provideTheme } from "@/fw/composables/theme";

export default defineComponent({
  name: "FwThemeWrapper",

  props: {
    ...makeThemeProps(),
  },

  setup(props) {
    const theme = provideTheme(props);
    
    return {
      theme,
    };
  },
});
</script>

<style lang="scss">
  @import "./fw__theme_wrapper.scss";
</style>
