<template>
  <section
    v-if="is_pc_scroll"
    class="ps-container"
    ref="scroll"
    @ps-scroll-y="scrollHandle"
    @ps-scroll-x="scrollHandle"
    @ps-scroll-up="scrollHandle"
    @ps-scroll-down="scrollHandle"
    @ps-scroll-left="scrollHandle"
    @ps-scroll-right="scrollHandle"
    @ps-y-reach-start="scrollHandle"
    @ps-y-reach-end="scrollHandle"
    @ps-x-reach-start="scrollHandle"
    @ps-x-reach-end="scrollHandle"
  >
    <slot></slot>
  </section>

  <section v-else class="ps-container mobile">
    <slot></slot>
  </section>
</template>

<script>
import PerfectScrollbar from "./lib/perfect-scrollbar/src/index";

export default {
  props: {
    swicher: {
      type: Boolean,
      default: true,
    },

    suppressScrollX: {
      type: Boolean,
      default: false,
    },

    suppressScrollY: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      ps: null,
    };
  },

  mounted() {
    if (this.$fw.display.smAndDown) {
      this.__uninit();
      return;
    }

    if (!this.$isServer) {
      this.__init();
    }
  },

  updated() {
    this.$nextTick(this.update);
  },

  activated() {
    this.__init();
  },

  deactivated() {
    this.__uninit();
  },

  beforeUnmount() {
    this.__uninit();
  },

  computed: {
    is_pc_scroll() {
      return !this.$fw.display.smAndDown;
    },

    settings() {
      return {
        suppressScrollX: this.suppressScrollX,
        suppressScrollY: this.suppressScrollY,
      };
    },
  },

  watch: {
    swicher(val) {
      if (val && !this._ps_inited) {
        this.__init();
      }
      if (!val && this._ps_inited) {
        this.__uninit();
      }
    },
    suppressScrollX() {
      this.__update();
    },

    suppressScrollY() {
      this.__update();
    },
  },

  methods: {
    __init() {
      this._ps_inited = true;
      try {
        this.ps = new PerfectScrollbar(this.$refs.scroll, this.settings);
      } catch (error) {
        //
      }
    },

    __uninit() {
      if (this.ps) {
        this.ps.destroy();
        this.ps = null;
        this._ps_inited = false;
      }
    },

    __update() {
      this.__uninit();
      this.__init();
    },

    scrollHandle(evt) {
      this.$emit(evt.type, evt);
    },
  },
};
</script>

<style lang="scss">
@import "./fw__scroll.scss";

.ps-container {
  position: relative;
}

.ps-container.mobile {
  overflow: auto !important;
}
</style>
