<template>
  <div class="fw-toggle-switch" :class="classes" @click="onChange">
    <input
      type="checkbox"
      v-model="computedModelValue"
      :value="value"
      :checked="computedIsChecked"
      :id="labelId"
      :disabled="disabled"
    />

    <label :for="labelId">
      <div class="switch">
        <div class="dot"></div>
      </div>
      <span v-if="label" class="label ml-2">{{ label }}</span>
    </label>
  </div>
</template>
<script>
import { defineComponent, computed } from "vue";
import { makeColorableProps, useColorable } from "@/fw/composables/colorable";
import { v4 as uuid } from "uuid";

export default defineComponent({
  name: "FwSwitch",
  props: {
    modelValue: { type: [Array, Boolean] },
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    label: {
      default: "",
    },
    color: {
      default: "primary",
    }
  },

  emits: ["change", "update:modelValue"],

  setup(props, { emit }) {
    const { colorClasses } = useColorable(props);
    const labelId = `fw-toggle-switch-${uuid()}`;

    const classes = computed(() => {
      if (Array.isArray(props.modelValue)) {
        return [
          props.modelValue.includes(props.value) ? colorClasses.value : "",
        ];
      } else {
        if (isModelValueBoolean()) {
          return [props.modelValue ? colorClasses.value : ""];
        } else {
          return [props.modelValue == props.value ? colorClasses.value : ""];
        }
      }
    });

    function isModelValueBoolean() {
      return typeof props.modelValue === "boolean";
    }

    const computedModelValue = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit("update:modelValue", value);
      },
    });

    function onChange() {
      emit("update:modelValue", props.modelValue);
      emit("change", props.modelValue);
    }

    const computedIsChecked = computed(() => {
      let value = props.value;
      let modelValue = props.modelValue;
      let isChecked = false;

      if (Array.isArray(modelValue)) {
        if (modelValue.includes(value)) {
          isChecked = true;
        } else {
          isChecked = false;
        }
      } else {
        isChecked = modelValue;
      }

      return isChecked;
    });

    return {
      labelId,
      computedModelValue,
      computedIsChecked,
      classes,
      onChange,
    };
  },
});
</script>

<style lang="scss">
@import "./fw__switch.scss";
</style>
