import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.activeProxy)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["cursor-pointer", _ctx.classes]),
            style: _normalizeStyle(_ctx.styles),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
          }, [
            _renderSlot(_ctx.$slots, "default", { close: _ctx.close })
          ], 6))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}