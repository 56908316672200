import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["rows", "type", "placeholder", "disabled"]
const _hoisted_2 = ["title", "data-title"]
const _hoisted_3 = {
  key: 0,
  class: "fw-textarea__detail px-3 py-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    id: _ctx.name,
    name: _ctx.name,
    modelValue: _ctx.inputValue,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.inputValue) = $event)),
    value: _ctx.modelValue,
    rules: _ctx.rulesExecutor,
    "validate-on-input": "",
    "validate-on-mount": _ctx.immediatelyValid,
    onInput: _ctx.updateValue
  }, {
    default: _withCtx(({ field }) => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.inheritClass)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["fw-textarea", _ctx.classes]),
          style: _normalizeStyle(_ctx.styles)
        }, [
          _createElementVNode("textarea", _mergeProps({
            ref: "fw__textarea",
            class: ["textarea mr-3", _ctx.coreTextareaClasses],
            rows: _ctx.rows
          }, field, {
            required: "",
            type: _ctx.type,
            placeholder: _ctx.placeholder,
            disabled: _ctx.disabled,
            autocomplete: "off",
            style: _ctx.coreTextareaStyles,
            onKeyup: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onKeyup && _ctx.onKeyup(...args))),
            onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onKeydown && _ctx.onKeydown(...args))),
            onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
            onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
            onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
          }), null, 16, _hoisted_1),
          _createElementVNode("label", {
            class: "label",
            title: _ctx.label,
            "data-title": _ctx.label
          }, null, 8, _hoisted_2)
        ], 6),
        (_ctx.showValid)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_Transition, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ErrorMessage, {
                    name: _ctx.name,
                    class: "d-block t-color-error"
                  }, null, 8, ["name"])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }, 8, ["id", "name", "modelValue", "value", "rules", "validate-on-mount", "onInput"]))
}