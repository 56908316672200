<template>
  <fw-paper
    class="fw-tab"
    :class="classes"
    :height="height"
    :min-height="minHeight"
    :max-height="maxHeight"
    :width="width"
    :min-width="minWidth"
    :max-width="maxWidth"
    @click="onClickTab"
  >
    <slot></slot>
  </fw-paper>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    value: {
      required: true,
    },

    height: {
      type: [String, Number],
      default: "",
    },
    minHeight: {
      type: [String, Number],
      default: "",
    },
    maxHeight: {
      type: [String, Number],
      default: "",
    },
    width: {
      type: [String, Number],
      default: "",
    },
    minWidth: {
      type: [String, Number],
      default: "",
    },
    maxWidth: {
      type: [String, Number],
      default: "",
    },
    syncSlideColor: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selected: false,
    };
  },

  computed: {
    isSelected() {
      return this.$parent.$parent.selected == this.value;
    },

    classes() {
      let activeClass = this.$parent.$parent.activeClass;

      return [
        {"synchronize-font-color": this.syncSlideColor},
        { selected: this.isSelected },
        this.isSelected ? activeClass : "",
      ];
    },
  },

  methods: {
    onClickTab() {
      this.$parent.$parent.onClickTab(this.value) == this.value;
    },
  },
});
</script>
