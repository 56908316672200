import FwTooltip from "./tooltip/fw__tooltip";
import FwRipple from "./ripple/fw__ripple";
import FwClickOutside from "./click_outside/fw__click_outside";

export default {
    install: (app: any) => { // eslint-disable-line
      app.use(FwTooltip);
      app.use(FwRipple);
      app.use(FwClickOutside);
    },
};
  