import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    as: "div",
    ref: "fw_form",
    name: "fw_form",
    onInvalidSubmit: _ctx.onInvalidSubmit,
    onSubmit: _ctx.onSubmit,
    onChange: _ctx.onChange,
    class: _normalizeClass(_ctx.fillHeight ? 'fill-height':'')
  }, {
    default: _withCtx(({ values, meta }) => [
      _createElementVNode("form", {
        class: _normalizeClass(_ctx.inheritClass)
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2),
      _createTextVNode(" " + _toDisplayString(_ctx.onChangeInnerForm(values, meta)), 1)
    ]),
    _: 3
  }, 8, ["onInvalidSubmit", "onSubmit", "onChange", "class"]))
}