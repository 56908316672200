<template>
  <div ref="fwCarousel" class="fw-carousel" :class="{'fw-carousel--hide-dot': hideDot}" @mousedown.left="onMouseDown">
    <fw-slide
      ref="slide"
      class="slide"
      :button-between="false"
      :button="!hideArrow"
      snap="none"
      @scroll-debounce="onScrollDebounce"
    >
      <template #btn-prev>
        <slot name="btn-prev"></slot>
      </template>
      <template #btn-next>
        <slot name="btn-next"></slot>
      </template>
      <slot></slot>
    </fw-slide>

    <div class="dots" v-if="!hideDot">
      <div
        class="dot"
        v-for="(num, idx) in dotCount"
        :class="{ current: idx === index }"
        :key="num"
        @click="onIndexClick(idx)"
      >
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import FwSlide from "../slide/fw__slide.vue";

const DEF_DIFFERENCE_OF_SWIPE_START_AND_END = 100;

export default {
  components: {
    FwSlide,
  },

  props: {
    modelValue: {},
    autoplay: {
      type: Boolean,
      default: false,
    },
    autoplayCount: {
      type: Number,
      default: 5000,
    },
    hideDot: {
      type: Boolean,
      default: false,
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },

    swipeable: {
      type: Boolean,
      default: false,
    },

    draggable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hasPrev: false,
      hasNext: false,
      interval: null,
      scrollWidth: 0,
      left: 0,
      progress: 0,
      index: 0,
      originX: 0,
      originLeft: 0,
    };
  },

  mounted() {
    observeVisibility(this.$refs.slide.$el, (visible) => {
      if (!this.autoplay) {
        return;
      }

      if (visible) {
        this.interval = setInterval(this.play, this.autoplayCount);
      } else {
        clearInterval(this.interval);
      }
    });

    if (this.$fw.func.util.is_mobile()) {
      let el = this.$refs.fwCarousel;

      swipedetect(el, (swipedir) => {
        if (!this.swipeable) {
          return;
        }

        if (swipedir === "left") {
          this.next();
        } else if (swipedir === "right") {
          this.prev();
        }
      });
    }
  },

  computed: {
    dotCount() {
      return this.$slots.default().length || 0;
    },
  },

  unmounted() {
    clearInterval(this.interval);
  },

  watch: {
    index(_newIndex) {
      if (_newIndex == this.dotCount - 1) {
        this.$emit("last");
      }
    },
  },

  methods: {
    onScrollDebounce({ hasNext, hasPrev, scrollWidth, width, left }) {
      this.hasPrev = hasPrev;
      this.hasNext = hasNext;
      this.scrollWidth = scrollWidth;
      this.left = left;
      this.progress = left / scrollWidth;
      this.index = Math.round(left / width);
    },

    onIndexClick(i) {
      this.$refs.slide.scrollToIndex(i);
    },

    play() {
      if (!this.hasNext && this.hasPrev) {
        this.$refs.slide.scrollToIndex(0);
        return;
      }

      if (this.hasNext) {
        this.$refs.slide.next();
      }
    },

    onMouseDown(e) {
      if( this.$fw.func.util.is_mobile() ) {
        return;
      }

      if (!this.swipeable && !this.draggable) {
        return;
      }

      this.originX = e.pageX;
      this.originLeft = this.left;

      if (this.swipeable) {
        window.addEventListener("mouseup", this.onMouseUpSwipe);
      }

      if (this.draggable) {
        window.addEventListener("mouseup", this.onMouseUp);
        window.addEventListener("mousemove", this.onMouseMove);
      }
    },

    onMouseUp() {
      window.removeEventListener("mouseup", this.onMouseUp);
      window.removeEventListener("mousemove", this.onMouseMove);
    },

    onMouseMove(e) {
      const offset = e.pageX - this.originX;
      const left = this.originLeft - offset;
      this.$refs.slide.scrollToLeft(left, "auto");
    },

    onMouseUpSwipe(e) {
      let diff = this.originX - e.pageX;

      if (Math.abs(diff) < DEF_DIFFERENCE_OF_SWIPE_START_AND_END) {
        return;
      }

      if (diff < 0) {
        this.$refs.slide.prev();
      } else {
        this.$refs.slide.next();
      }

      window.removeEventListener("mouseup", this.onMouseUpSwipe);
    },

    prev() {
      this.$refs.slide.prev();
    },

    next() {
      this.$refs.slide.next();
    },
  },
};

function swipedetect(el, callback) {
  let touchsurface = el;
  let swipedir;
  let startX;
  let startY;
  let distX;
  let distY;
  let threshold = 150; //required min distance traveled to be considered swipe
  let restraint = 100; // maximum distance allowed at the same time in perpendicular direction
  let allowedTime = 300; // maximum time allowed to travel that distance
  let elapsedTime;
  let startTime;

  let handleswipe =
    callback ||
    function (swipedir) {
      //
    };

  touchsurface.addEventListener(
    "touchstart",
    function (e) {
      let touchobj = e.changedTouches[0];
      swipedir = "none";
      let dist = 0;
      startX = touchobj.pageX;
      startY = touchobj.pageY;
      startTime = new Date().getTime(); // record time when finger first makes contact with surface
      e.preventDefault();
    },
    false
  );

  touchsurface.addEventListener(
    "touchmove",
    function (e) {
      e.preventDefault(); // prevent scrolling when inside DIV
    },
    false
  );

  touchsurface.addEventListener(
    "touchend",
    function (e) {
      var touchobj = e.changedTouches[0];
      distX = touchobj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
      distY = touchobj.pageY - startY; // get vertical dist traveled by finger while in contact with surface
      elapsedTime = new Date().getTime() - startTime; // get time elapsed
      if (elapsedTime <= allowedTime) {
        // first condition for awipe met
        if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
          // 2nd condition for horizontal swipe met
          swipedir = distX < 0 ? "left" : "right"; // if dist traveled is negative, it indicates left swipe
        } else if (
          Math.abs(distY) >= threshold &&
          Math.abs(distX) <= restraint
        ) {
          // 2nd condition for vertical swipe met
          swipedir = distY < 0 ? "up" : "down"; // if dist traveled is negative, it indicates up swipe
        }
      }
      handleswipe(swipedir);
      e.preventDefault();
    },
    false
  );
}

function observeVisibility(element, callback) {
  const observer = new IntersectionObserver(
    (records) => {
      callback(records.find((record) => record.isIntersecting));
    },
    { rootMargin: "10% 0% 10% 0%", threshold: 0.5 }
  );
  observer.observe(element);
}
</script>

<style lang="scss">
@import "./fw__carousel.scss";
</style>
