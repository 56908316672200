<template>
  <div
    class="fw-dropdown-menu"
    :class="classes"
    ref="dropdown_menu"
    v-click-outside="{
      handler: onClickOutside,
      include: onClickOutsideIncluded,
    }"
  >
    <div class="fw-dropdown-menu--content" v-if="ui.status.is_show">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    active: { type: Boolean, default: false },

    clickOnClose: {
      type: Boolean,
      default: true,
    },

    fluid: {
      type: Boolean,
      default: false,
    },

    layer: {
      type: Boolean,
      default: false,
    },

    nowrap: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      ui: {
        status: {
          is_show: false,
        },
      },
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.isActive = this.ui.status.is_show;
      let el_parent = this.$refs.dropdown_menu.parentElement;

      el_parent.classList.add("fw-dropdown-menu--parent");
      el_parent.addEventListener("click", this.handleContentVisibility);
    });
  },

  beforeUnmount() {
    this.$refs.dropdown_menu.parentElement.removeEventListener(
      "click",
      this.handleContentVisibility
    );
  },

  computed: {
    classes() {
      return {
        "fw-dropdown-menu__fluid": this.fluid,
        "fw-dropdown-menu__layer": this.layer,
        "fw-dropdown-menu__nowrap": this.nowrap,
      };
    },
  },

  methods: {
    handleContentVisibility(_event) {
      if (!this.ui.status.is_show) {
        this.ui.status.is_show = true;
        this.emitChange();
        return;
      }

      let is_clicked_content =
        _event.target.closest(".fw-dropdown-menu--content") != null;

      if (is_clicked_content && !this.clickOnClose) {
        return;
      }

      if (this.ui.status.is_show) {
        this.ui.status.is_show = false;
        this.emitChange();
        return;
      }
    },

    onClickOutside() {
      if (!this.ui.status.is_show) {
        return;
      }

      this.ui.status.is_show = false;
      this.emitChange();
    },

    onClickOutsideIncluded() {
      let el_parent = this.$refs.dropdown_menu.parentElement;
      return [el_parent];
    },

    emitChange() {
      this.$emit("change", this.ui.status.is_show);
    },
  },
});
</script>

<style lang="scss">
@import "./fw__dropdown_menu.scss";
</style>
