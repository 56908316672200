// Utilities
import { computed, isRef } from "vue";
import { propsFactory, propValidator } from "@/fw/js/util";

// Types
import type { Ref } from "vue";

type ColorableValue = string;

export interface ColorableProps {
  color: ColorableValue;
}

type ColorableData = {
  colorClasses: Ref<string[]>;
};

// Composables
export const makeColorableProps = propsFactory(
  {
    color: {
      type: String,
      default: "",
    },
  },
  "color"
);

export function useColorable(props: ColorableProps | Ref<ColorableValue>): ColorableData {
  const colorClasses = computed(() => {
    const color = isRef(props) ? props.value : props.color;
    const classes: string[] = [];

    if( !color) {
      return classes;
    }

    classes.push(`t-color-${color}`);

    return classes;
  });

  return { colorClasses };
}
