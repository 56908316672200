import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_ripple = _resolveDirective("ripple")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["l-card pa-0", _ctx.classes]),
    style: _normalizeStyle(_ctx.styles),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 6)), [
    [_directive_ripple, _ctx.ripple]
  ])
}