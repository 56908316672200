/* eslint-disable */

// Utilities
import {
  computed,
  inject,
  provide,
  reactive,
  ref,
  watch,
} from "vue";
import {
  colorToRGB,
  getCurrentInstance,
  IN_BROWSER,
  propsFactory,
} from "@/fw/js/util";

import { theme } from "@/fw/fw_config/theme";

// Types
import type { App, DeepReadonly, InjectionKey, Ref } from "vue";

type DeepPartial<T> = T extends object ? { [P in keyof T]?: DeepPartial<T[P]> } : T;

export type ThemeOptions =
  | false
  | {
      defaultTheme?: string;
      themes?: Record<string, ThemeDefinition>;
    };

export type ThemeDefinition = DeepPartial<InternalThemeDefinition>;

interface InternalThemeOptions {
  defaultTheme: string;
  themes: Record<string, InternalThemeDefinition>;
}

interface InternalThemeDefinition {
  dark: boolean;
  colors: Colors;
}

export interface Colors extends BaseColors  {
  [key: string]: string;
}

interface BaseColors {
  background: string;
  surface: string;
  primary: string;
  secondary: string;
  success: string;
  warning: string;
  error: string;
  info: string;
}

export interface ThemeInstance {
  readonly themes: Ref<Record<string, InternalThemeDefinition>>;

  readonly name: Readonly<Ref<string>>;
  readonly current: DeepReadonly<Ref<InternalThemeDefinition>>;
  readonly computedThemes: DeepReadonly<
    Ref<Record<string, InternalThemeDefinition>>
  >;

  readonly themeClass: Readonly<Ref<string | undefined>>;
  readonly styles: Readonly<Ref<string>>;

  readonly global: {
    readonly name: Ref<string>;
    readonly current: DeepReadonly<Ref<InternalThemeDefinition>>;
  };
}

export const ThemeSymbol: InjectionKey<ThemeInstance> =
  Symbol.for("fw:theme");

export const makeThemeProps = propsFactory(
  {
    theme: String,
  },
  "theme"
);

const defaultThemeOptions: Exclude<ThemeOptions, false> = {
  defaultTheme: "light",
  themes: {
    light: {
      colors: {...theme.light},
    },
    dark: {
      colors: {...theme.dark},
    }
  },
};

function parseThemeOptions(): InternalThemeOptions {
  return defaultThemeOptions as InternalThemeOptions;
}

// Composables
export function createTheme(): ThemeInstance & { install: (app: App) => void } {
  const parsedOptions = reactive(parseThemeOptions());
  const name = ref(parsedOptions.defaultTheme);
  const themes = ref(parsedOptions.themes);

  const computedThemes = computed(() => {
    const acc: Record<string, InternalThemeDefinition> = {};

    for (const [name, original] of Object.entries(themes.value)) {
      acc[name] = {...original};
    }

    return acc;
  });

  const current = computed(() => computedThemes.value[name.value]);

  const styles = computed(() => {
    const lines: string[] = [];

    for (const [themeName, theme] of Object.entries(computedThemes.value)) {
      if( themeName == name.value ) {
        createCssClass(lines, `:root`, [
          ...genCssVariables(theme),
        ]);
      }
    }

    const bgLines: string[] = [];
    const fgLines: string[] = [];

    const colors = new Set(
      Object.values(computedThemes.value).flatMap((theme) =>
        Object.keys(theme.colors)
      )
    );

    for (const key of colors) {
      createCssClass(bgLines, `.bg-${key}`, [
        `background-color: var(--fw-theme-${key}) !important`,
        `background-image: var(--fw-theme-${key})`,
      ]);
      createCssClass(fgLines, `.t-color-${key}`, [
        `color: var(--fw-theme-${key}) !important`,
      ]);
      createCssClass(fgLines, `.border-${key}`, [
        'border: 1px solid var(--fw-theme-' + key.replace(/^on-/, '') + ') !important',
      ]);
    }

    lines.push(...bgLines, ...fgLines);
    return lines.map((str, i) => (i === 0 ? str : `    ${str}`)).join("");
  });

  function install() {
    let styleEl = IN_BROWSER? document.getElementById("fw-theme") : null;

    watch(styles, updateStyles, { immediate: true });

    function updateStyles() {
      if (typeof document !== "undefined" && !styleEl) {
        const el = document.createElement("style");
        el.type = "text/css";
        el.id = "fw-theme";

        styleEl = el;
        document.head.appendChild(styleEl);
      }

      if (styleEl) {
        styleEl.innerHTML = styles.value;
      } 
    }
  }

  const themeClass = computed(() => {
    return `fw-theme--${name.value}`;
  });

  return {
    install,
    name,
    themes,
    current,
    computedThemes,
    themeClass,
    styles,
    global: {
      name,
      current,
    },
  };
}

export function provideTheme(props: { theme?: string }) {
  getCurrentInstance("provideTheme");

  const theme = inject(ThemeSymbol, null);

  if (!theme) throw new Error();

  const name = computed<string>(() => {
    return props.theme ?? theme?.name.value;
  });

  const themeClass = computed(() => {
    return `fw-theme--${name.value}`;
  });

  const newTheme: ThemeInstance = {
    ...theme,
    name,
    themeClass,
  };

  provide(ThemeSymbol, newTheme);

  return newTheme;
}

function createCssClass(lines: string[], selector: string, content: string[]) {
  lines.push(
    `${selector} {\n`,
    ...content.map((line) => `  ${line};\n`),
    "}\n"
  );
}

function genCssVariables(theme: InternalThemeDefinition) {
  const variables: string[] = [];
  for (const [key, value] of Object.entries(theme.colors)) {
    if( !value.startsWith("#") ) {
      variables.push(`--fw-theme-${key}: ${value}`);
      continue;
    }

    const rgb = colorToRGB(value);

    variables.push(`--fw-theme-rgb-${key}: ${rgb.r},${rgb.g},${rgb.b}`);
    variables.push(`--fw-theme-${key}: ${value}`);
  }

  return variables;
}
