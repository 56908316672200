import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (!_ctx.hideTooltip)
    ? _withDirectives((_openBlock(), _createElementBlock("span", { key: _ctx.updateKey }, [
        _createTextVNode(_toDisplayString(_ctx.truncate__string__get(_ctx.value, _ctx.visibleStringLength)), 1)
      ])), [
        [_directive_tooltip, {
      placement: _ctx.tooltip_options.placement,
      content: _ctx.tooltip_options.content,
      class: _ctx.tooltip_options.tooltip_class,
    }]
      ])
    : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.truncate__string__get(_ctx.value, _ctx.visibleStringLength)), 1))
}