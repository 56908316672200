import {
  computed, effectScope, getCurrentInstance, onScopeDispose, ref, toRaw, watch,
} from 'vue';

// Types
import type { ComponentInternalInstance, EffectScope, Ref } from 'vue';

const stack = ref<ComponentInternalInstance[]>([]);

export function useStack(isActive: Ref<boolean>) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const vm = getCurrentInstance()!;
  let scope: EffectScope | undefined;

  watch(isActive, (val) => {
    if (val) {
      scope = effectScope();
      scope.run(() => {
        stack.value.push(vm);

        onScopeDispose(() => {
          const idx = stack.value.indexOf(vm);
          stack.value.splice(idx, 1);
        })
      })
    } else {
      scope?.stop();
    }
  }, { immediate: true });

  const isTop = computed(() => {
    return toRaw(stack.value[stack.value.length - 1]) === vm;
  });

  const isStackEmpty = computed(() => {
    return toRaw(stack.value.length == 0);
  });

  return {
    isTop,
    isStackEmpty,
  }
}
