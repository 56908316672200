import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Doughnut = _resolveComponent("Doughnut")!

  return (_openBlock(), _createBlock(_component_Doughnut, {
    width: _ctx.width,
    "css-classes": _ctx.cssClasses,
    height: _ctx.height,
    "chart-id": _ctx.chartId,
    "chart-data": _ctx.chartData,
    "chart-options": _ctx.chartOptions
  }, null, 8, ["width", "css-classes", "height", "chart-id", "chart-data", "chart-options"]))
}