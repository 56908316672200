<template>
  <div>
    <TransitionGroup name="list">
      <slot></slot>
    </TransitionGroup>
  </div>
</template>

<script>
  import { defineComponent, reactive, toRefs } from "vue";


  export default defineComponent({
    name: "FwListTransition",

    // props: {
      //
    // },

    setup(){
      const state = reactive({
        //
      });

      return {
        ...toRefs(state),
      }
    }
  });
</script>

<style lang="scss">
@import "./fw__list_transition.scss";
</style>