<template>
  <Field
    :id="name"
    :name="name"
    v-model="inputValue"
    :value="modelValue"
    :rules="rulesExecutor"
    :standalone="standalone"
    :validate-on-mount="immediatelyValid"
    v-slot="{ field }"
    @input="updateValue"
  >
    <div :class="inheritClass">
      <div class="fw-input" :class="classes" :style="styles">
        <div class="prepend-inner" v-if="$slots['prepend-inner']">
          <slot name="prepend-inner"></slot>
        </div>
        <div class="inp-wrapper">
          <input
            ref="fw__input"
            class="inp mr-3"
            :class="coreInputClass"
            v-bind="field"
            :type="type"
            :placeholder="placeholder"
            :disabled="disabled"
            :readonly="readonly"
            autocomplete="off"
            @keyup="onKeyup"
            @keydown="onKeydown"
            @focus="onFocus"
            @blur="onBlur"
            @change="onChange"
            @keypress="onKeypress"
            @paste="onPaste"
          />
          <label class="label" :title="label" :data-title="label"></label>
        </div>

        <div
          v-if="clearable && !disabled && inputValue"
          class="append-inner clearable mr-1"
          @click="onClickClear"
        >
          <fw-icon class="mr-1" icon="close" size="20" color="desc" />
        </div>

        <div class="append-inner" v-if="$slots['append-inner']">
          <slot name="append-inner"></slot>
        </div>
      </div>

      <div v-if="showValid" class="fw-input__detail px-3 py-1">
        <transition>
          <ErrorMessage
            :name="name"
            class="d-block t-color-error"
          ></ErrorMessage>
        </transition>
      </div>
    </div>
  </Field>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  toRefs,
  computed,
  watch,
  nextTick,
} from "vue";
import {
  Form,
  Field,
  ErrorMessage,
  GenericValidateFunction,
  useFieldError,
  useValidateField,
} from "vee-validate";

import {
  makeBackgroundColorProps,
  useBackgroundColor,
} from "@/fw/composables/backgroundable";
import { makeBorderProps, useBorder } from "@/fw/composables/border";
import { makeDimensionProps, useDimension } from "@/fw/composables/dimensions";
import { makeShadowProps, useShadow } from "@/fw/composables/boxShadows";
import { makeRoundedProps, useRounded } from "@/fw/composables/rounded";
import { propValidator } from "@/fw/js/util";
import { v4 } from "uuid";
import { c_func } from "@/fw/js/functions.js"

export default defineComponent({
  name: "FwInput",

  components: {
    Field,
    ErrorMessage,
  },

  props: {
    class: {
      type: String,
    },
    modelValue: {
      // v-model
      type: [String, Number],
    },
    name: {
      // 이름
      type: String,
      default: () => v4(),
    },
    rules: {
      // 유효성검사 함수
      type: [Function, Array, Boolean, String],
      default: () => true,
    },
    standalone: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    showValid: {
      // 유효성검사 알림 메시지 보이기
      type: Boolean,
      default: false,
    },
    immediatelyValid: {
      // mounted 되자마자 valid 바로 실행
      type: Boolean,
      default: false,
    },
    type: {
      //
      type: String,
      default: "text",
    },
    noFocus: {
      type: Boolean, // focus 효과 없애기
      default: false,
    },
    size: {
      type: String,
      ...propValidator("size", ["small", "normal", "large", "x-large"]),
    },
    placeholder: {
      type: String,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    disabled: Boolean,
    readonly: {
      type: Boolean,
      default: false,
    },
    ...makeBorderProps(),
    ...makeDimensionProps(),
    ...makeShadowProps(),
    ...makeBackgroundColorProps(),

    // number only
    numberOnly: {
      type: Boolean,
      default: false,
    },

    precision: {
      type: Number,
      default: 10,
    },

    maxDecimal: {
      type: Number,
      default: 12,
    },

    priceFormat: {
      type: Boolean,
      default: true,
    },

    positive: {
      type: Boolean,
      default: false,
    },

    valueWhenEmpty: {
      type: String,
      default: "", // "0" or "" or null
    },

    rounded: {
      type: String, 
      default: "md",
    }
  },

  emits: [
    "input",
    "keyup",
    "keydown",
    "click",
    "click:clear",
    "update:modelValue",
    "inputValid",
    "focus",
    "blur",
    "change",
  ],

  setup(props, { emit }) {
    const { borderClasses } = useBorder(props);
    const { dimensionStyles } = useDimension(props);
    const { shadowClasses } = useShadow(props);
    const { roundedClasses } = useRounded(props);
    const { backgroundClasses } = useBackgroundColor(props);

    const fw__input = ref();
    const func = c_func;

    const stateFocus = ref(false);

    const stateValid = reactive({
      inputValue: "",
      isValid: true,
    });

    const computedModelValue = computed(() => {
      return props.modelValue;
    })

    const inheritClass = computed(() => props.class);

    const classes = computed(() => {
      return [
        borderClasses.value,
        shadowClasses.value,
        roundedClasses.value,
        backgroundClasses.value,
        props.size,
        props.label != "" ? "has-label" : "",
        stateValid.isValid ? "" : "fw-input__invalid",
        stateFocus.value && !props.noFocus ? "fw-input__focus" : "",
      ];
    });

    const coreInputClass = computed(() => {
      return [
        stateValid.inputValue ? "fw-input__has-value" : "",
        props.reverse ? 'text-right' : '',
      ];
    })

    const styles = computed(() => {
      return [dimensionStyles.value];
    });

    // ==========================================
    // rules 실행
    const rulesExecutor = () => {
      if (Array.isArray(props.rules)) {
        let ruleCatched = props.rules.find(
          (_rule) => typeof _rule(stateValid.inputValue) === "string"
        );
        if (ruleCatched === undefined) {
          return true;
        }
        return ruleCatched(stateValid.inputValue);
      } else if (typeof props.rules === "function") {
        return props.rules(stateValid.inputValue);
      } else {
        return props.rules;
      }
    };

    const validate = useValidateField(props.name);
    const message = useFieldError(props.name);

    function focus() {
      fw__input.value.focus();
    }

    // 이벤트 전달
    function onKeyup() {
      emit("keyup");
    }

    function onKeydown() {
      emit("keydown");
    }

    function onFocus() {
      emit("focus");
      stateFocus.value = true;
    }

    function onBlur() {
      emit("blur");
      stateFocus.value = false;
    }

    function onChange() {
      emit("change");
    }

    function onClickClear() {
      if (props.disabled) {
        return;
      }

      let el_input = fw__input.value;
      stateValid.inputValue = "";
      el_input.value = "";
      emit("click:clear");
      emit("update:modelValue", null);
    }

    function onPaste(_event) {
      // 붙여넣기시 소수점과 숫자만 입력되도록 
      let _text = _event.clipboardData.getData("text/plain");
      if (props.numberOnly) {
        _text = _text.replace(/[^0-9.]/g, "");
      }
      _event.preventDefault();
      document.execCommand("insertText", false, _text);
    }

    function onKeypress(_event) {
      // 숫자 콤마 소수점만 입력가능
      // 1. 숫자만 입력
      // 2. 소수점은 한번만 입력
      // 3. 소수점이 있을 경우 소수점 뒤는 2자리까지만 입력가능
      // 4. 소수점이 없을 경우 12자리까지만 입력가능
      // 5. 소수점을 입력한 후 0을 입력하면 0이 입력되지 않음
      // 6. 첫번째 입력이 소수점이면 0을 앞에 붙여줌
      // 7. 첫번째 입력이 0일 경우 두번째 입력이 0이 아닌 숫자일 경우 0이 입력되지 않음
      // 8. 0으로 시작하는 숫자를 입력할 경우 0이 입력되지 않음
      // 9. 0으로 시작하는 숫자를 입력할 경우 0을 제외하고 입력
      // 10. 복사 붙여넣기 가능
      // 11. 정수부에는 가격단위 콤마 삽입
      // 12. 마이너스 입력 가능
      // 13. 마이너스는 한번만 입력 가능
      // 14. 마이너스는 첫번째 입력만 가능
      // 15. positive 속성이 true일 경우 마이너스 입력 불가능
      // 16. 소수점 끝에 0이 이미 있을 경우 0 입력 불가능
      // 17. 정수부에 가격단위 콤마 삽입

      if( !props.numberOnly ) {
        return;
      }

      // 숫자만 입력
      let key = _event.key;

      if (isNaN(key) && key !== "." && key !== "-") {
        _event.preventDefault();
        return;
      }

      let el_input = fw__input.value;
      let value = el_input.value;

      // 첫번째 입력이 소수점이면 0을 앞에 붙여줌
      if (key === "." && value === "") {
        el_input.value = "0.";
        _event.preventDefault();
        return;
      }

      // 소수점은 한번만 입력
      if (key === "." && value.indexOf(".") !== -1) {
        _event.preventDefault();
        return;
      }

      if (value.indexOf(".") > -1 && key !== ".") {
        // 소수점이 있을 경우 소수점 뒤는 props.precision자리까지만 입력가능
        let afterDot = value.split(".")[1];
        if (afterDot.length >= props.precision) {
          _event.preventDefault();
          return;
        }
      }

      if (value.indexOf(".") === -1 && key !== ".") {
        // 소수점이 없을 경우 콤마가 있다면 콤마를 제거하고 props.maxDecimal자리까지만 입력가능
        let noComma = value.replace(",", "");
        if (noComma.length >= props.maxDecimal) {
          _event.preventDefault();
          return;
        }
      }

      if (value.indexOf("-") > -1 && key === "-") {
        // 마이너스는 한번만 입력 가능
        _event.preventDefault();
        return;
      }

      if (value.indexOf("-") === -1 && key === "-") {
        // 마이너스는 첫번째 입력만 가능
        if (value !== "") {
          _event.preventDefault();
          return;
        }
      }

      if (props.positive && key === "-") {
        // positive 속성이 true일 경우 마이너스 입력 불가능
        _event.preventDefault();
        return;
      }
    }

    const updateValue = (_event) => {
      let target = _event.target;
      stateValid.inputValue = target.value;
      emit("update:modelValue", stateValid.inputValue);
      validate();
    };

    const inputValueSet = (_value) => {
      stateValid.inputValue = _value;
    }

    watch(
      () => stateValid.inputValue, 
      (_value) => {
        if( props.priceFormat && props.numberOnly ) {
          stateValid.inputValue = func.num.comma.insert(_value);

          emit("input", func.num.comma.remove(stateValid.inputValue));
          emit("update:modelValue", func.num.comma.remove(stateValid.inputValue));
          return;
        }

          stateValid.inputValue = _value;
          emit("input", stateValid.inputValue);
          emit("update:modelValue", _value);
      }
    )

    watch(
      () => message.value,
      (_value) => {
        stateValid.isValid = typeof _value === "string" ? false : true;
      }
    );

    watch(
      () => computedModelValue.value,
      (_value) => {
        stateValid.inputValue = _value;
      }
    );

    onMounted(() => {
      fw__input.value;
      stateValid.inputValue = props.modelValue;
    });

    return {
      fw__input,
      Form,
      Field,
      ErrorMessage,

      validate,
      message,

      ...toRefs(stateValid),

      rulesExecutor,

      updateValue,
      onKeyup,
      onKeydown,
      onFocus,
      onBlur,
      onChange,
      onPaste,
      onClickClear,
      onKeypress,
      classes,
      coreInputClass,
      inheritClass,
      styles,

      inputValueSet,
      focus,
    };
  },
});
</script>

<style lang="scss">
@import "./fw__input.scss";
</style>
