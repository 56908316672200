
import { makeBorderProps, useBorder } from "@/fw/composables/border";
import { makeDimensionProps, useDimension } from "@/fw/composables/dimensions";
import { makeShadowProps, useShadow } from "@/fw/composables/boxShadows";
import { makeRoundedProps, useRounded } from "@/fw/composables/rounded";
import { makeBackgroundColorProps, useBackgroundColor } from "@/fw/composables/backgroundable";
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "FwCard",

  props: {
    ripple: {
      type: Boolean,
      default: false,
    },
    grow: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    ...makeBorderProps(),
    ...makeDimensionProps(),
    ...makeShadowProps(),
    ...makeRoundedProps(),
    ...makeBackgroundColorProps(),
  },

  emits: ["click"],

  setup(props, { emit }) {
    const { borderClasses } = useBorder(props);
    const { dimensionStyles } = useDimension(props);
    const { shadowClasses } = useShadow(props);
    const { roundedClasses } = useRounded(props);
    const { backgroundClasses } = useBackgroundColor(props);

    const classes = computed(() => {
      return [backgroundClasses.value, borderClasses.value, shadowClasses.value, roundedClasses.value, { "flex-grow-1": props.grow }];
    });

    const styles = computed(() => {
      return [dimensionStyles.value];
    });

    const onClick = ($event: Event) => {
      emit("click", $event);
    };

    return {
      classes,
      styles,
      onClick,
    };
  },
});
