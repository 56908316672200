<template>
  <fw-card
    ref="refListItem"
    class="fw-list-item d-flex align-center"
    :class="[isSelected ? 'fw-list-item--active' : '', classes]"
    :min-height="minHeight"
    rounded="0"
    :ripple="ripple"
    :style="styles"
    @click="event.click.listItem"
  >
    <slot></slot>

    <div class="fw-list-item__bg" :class="`t-color-${activeColor}`" />

    <fw-paper v-if="isSelected" class="ml-auto" min-width="30">
      <div v-if="isSelected">
        <fw-icon class="fw-list-item__icon" :color="activeColor" size="30" icon="check-circle"></fw-icon>
      </div>
    </fw-paper>
    
    <slot name="append"></slot>
  </fw-card>
</template>

<script>
// todo
// 현재는 리스트아이템의 v-model을 통한 active효과만을 가진 컴포넌트임
// 필요: 슬롯(이미지, 좌측, 오른쪽), 이벤트 등을 통해 커스텀이 가능하도록 개선

import { makeBorderProps, useBorder } from "@/fw/composables/border";
import { makeDimensionProps, useDimension } from "@/fw/composables/dimensions";
import { makeShadowProps, useShadow } from "@/fw/composables/boxShadows";
import { makeBackgroundColorProps, useBackgroundColor } from "@/fw/composables/backgroundable";

import { computed, ref, getCurrentInstance } from "vue";
import { v4 } from "uuid";
import { FwCard, FwIcon } from "@/fw/components";

export default {
  name: "FwListItemSolo",

  components: {
    FwCard,
    FwIcon,
  },

  props: {
    modelValue: {},
    value: {
      type: [String, Number],
    },
    selected: {
      type: Boolean,
      default: false,
    },
    ripple: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    ...makeBorderProps(),
    ...makeDimensionProps(),
    ...makeShadowProps(),
    ...makeBackgroundColorProps(),
    activeColor: {
      type: String,
      default: "primary",
    },
  },

  emits: ["click", "update:modelValue"],

  setup(props, { emit }) {
    const { borderClasses } = useBorder(props);
    const { dimensionStyles } = useDimension(props);
    const { shadowClasses } = useShadow(props);
    const { backgroundClasses } = useBackgroundColor(props);

    const instance = getCurrentInstance();

    const refListItem = ref(null);

    const classes = computed(() => {
      return [backgroundClasses.value, borderClasses.value, shadowClasses.value];
    });

    const styles = computed(() => {
      return [
        dimensionStyles.value,
      ];
    });

    const event = {
      click: {
        listItem: () => {
          computedModelValue.value = computedValue.value;
          emit("click");
        },
      },
    };

    const computedValue = computed(() => {
      if (props.value) {
        return props.value;
      }

      if (instance.vnode.key) {
        return instance.vnode.key;
      } else {
        return v4();
      }
    });

    const computedModelValue = computed({
      get() {
        return props.modelValue;
      },
      set(_value) {
        if (!Array.isArray(props.modelValue)) {
          emit("update:modelValue", computedValue.value);
        } else {
          if (props.modelValue.includes(_value)) {
            let arr_modelValue = [...props.modelValue].filter((_item) => _item !== _value);
            emit("update:modelValue", arr_modelValue);
          } else {
            let arr_modelValue = [...props.modelValue, _value];
            emit("update:modelValue", arr_modelValue);
          }
        }
      },
    });

    const isSelected = computed(() => {
      if (Array.isArray(props.modelValue)) {
        return props.selected || props.modelValue.includes(computedValue.value);
      }

      return props.selected || props.modelValue === computedValue.value;
    });

    return {
      refListItem,
      classes,
      styles,
      event,
      isSelected,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./fw__list_item.scss";
</style>
