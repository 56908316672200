<template>
  <!-- :style="styles" -->
  <div class="fw-container" :class="[classes]">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { computed } from "vue";
import { propValidator } from "@/fw/js/util";
import { useFw } from "@/fw";

export default defineComponent({
  name: "FwContainer",
  props: {
    size: {
      default: "md",
      ...propValidator("size", ["sm", "md", "lg", "xl", "xxl"]),
    },

    fluid: {
      type: Boolean,
      default: false,
    },

    bottomNav: {
      type: Boolean,
      default: false,
    },

    header: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const fw = useFw();
    const classes = computed(() => {
      return [
        props.size != "" ? `fw-container--${props.size}` : "",
        {
          "fw-container--fluid": props.fluid,
        },
      ];
    });

    const styles = computed(() => {
      const bottomNavHeight = props.bottomNav? "60px" : "0px"; 
      const headerHeight = props.header? "60px" : "0px"; 
      return {
        height: fw.display.smAndDown ? (props.bottomNav || props.header ? `calc(100% + ${bottomNavHeight} - ${headerHeight})` : "100%") : "auto",
      };
    });

    return {
      classes,
      styles,
    };
  },
});
</script>

<style lang="scss">
@import "./fw__container.scss";
</style>
