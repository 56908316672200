<template>
  <div v-html="formatDocument"></div>
</template>

<script>
export default {
  name: "FwDocumentViewer",
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
  },

  data() {
    return {};
  },

  computed: {
    formatDocument() {
      let text = this.value;

      text = text.replace(/./gm, function (s) {
        return s.match(/[a-z0-9\s]+/i) ? s : "&#" + s.charCodeAt(0) + ";";
      });

      // 첫 제목 강조
      text = text.replace(/(.*?)\r\n/, function (s) {
        return (
          "<p class='text-title-sm font-weight-bold mt-8 mb-4'>" + s + "</p>\r\n"
        );
      });

      // 중간 항목 강조
      text = text.replace(/\r\n\r\n(.*?)\r\n/g, function (s) {
        return (
          "<p class='text-medium font-weight-bold mt-4 mb-2'>" + s + "</p>"
        );
      });

      return text;
    },
  },
};
</script>
