<template>
  <p>
    <slot name="prepend">
    </slot>
    <span>
      {{ computedGetSign }}
    </span>
    {{ computedValue }}
    <span class="text-uppercase" :class="unitClass">
      {{ unit }}
    </span>
  </p>
</template>

<script>
import { computed, } from "vue";
import { c_func } from "@/fw/js/functions.js"; 

export default {
  name: "FwNumFormatter",
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },

    precision: {
      type: Number,
      default: 2,
    },

    unit: {
      type: String,
      default: "",
    },

    unitClass: {
      type: String,
      default: "",
    },

    sign: {
      type: Boolean,
      default: false,
    }
  },

  setup(props, { emit }) {
    // 숫자(주로 수량 및 가격 등)를 포맷팅 하는 컴포넌트 
    
    const computedValue = computed(() => {
      let value = props.value;
      let precision = props.precision;
      let result;

      if (value === 0) {
        return 0;
      }

      // 소수부가 있다면 정수부분과 소수부분을 분리한다. 
      let arr_value = value.toString().split(".");

      // 정수부분을 포맷팅한다.
      let int_value = arr_value[0];

      // 소수부분을 포맷팅한다.
      let float_value = arr_value[1];

      // 정수부분을에 가격단위 콤마를 삽입한다. 
      let int_value_format = c_func.num.comma.insert(int_value);

      // 소수부분을 포맷팅한다 -> precision 만큼만 남긴다.
      if (float_value) {
        float_value = float_value.substring(0, precision);
      }

      // 소수부분이 있다면 정수부분과 소수부분을 합친다.
      if (float_value) {
        result = int_value_format + "." + float_value;
      } else {
        result = int_value_format;
      }

      return result;
    });

    const computedGetSign = computed(() => {
      let isSign = props.sign;
      let value = props.value;
      let result = "";

      if( !isSign ) {
        return "";
      }

      if (value > 0) {
        result = "+";
      }

      return result;
    });

    return { 
      computedValue,
      computedGetSign,
    }
  },
};
</script>
