// Utilities
import { computed, isRef } from "vue";
import { propsFactory, propValidator } from "@/fw/js/util";
// Types
import type { Ref } from "vue";
export interface ShadowProps {
  shadow?: boolean | string | null;
}

const shadowOptions = [true, false, "0", "sm", "md", "lg", "xl"];

// Composables
export const makeShadowProps = propsFactory(
  {
    shadow: {
      type: [String, Boolean],
      ...propValidator("shadow", shadowOptions),
    },
  },
  "shadow"
);

type ShadowData = {
  shadowClasses: Ref<string[]>;
};

export function useShadow(
  props: ShadowProps | Ref<number | string | undefined>
): ShadowData {
  const shadowClasses = computed(() => {
    let shadow = isRef(props) ? props.value : props.shadow;
    const classes: string[] = [];

    if (shadow == null) return classes;
    if (shadow == false) return classes;
    if (shadow == true) shadow = "normal";

    classes.push(`shadow-${shadow}`);

    return classes;
  });

  return { shadowClasses };
}
