<template>
  <div>
    <fw-dialog
      v-model="ui.modal.isActive"
      :width="computeModalWidthSize"
      :height="computeModalHeightSize"
      :close-on-back="false"
      :content-class="computeModalRadius"
    >
      <fw-row class="d-flex px-5 pt-1">
        <fw-col class="px-0 py-4">
          <span class="text-title-xxs font-weight-bold">{{
            $tt("$vuetify.search.language_title")
          }}</span>
        </fw-col>
        <fw-col
          class="align-center justify-end pa-0"
          style="display: flex; align-items: center"
        >
          <fw-img
            width="12px"
            :src="require('/src/assets/img/change_language_exit.png')"
            class="cursor-pointer"
            @click="closeModal"
          />
        </fw-col>
      </fw-row>
      <fw-row class="mt-0 px-5">
        <fw-col class="px-0 pt-5 pb-0">
          <div class="input-container">
            <fw-input
              :placeholder="$tt(`$vuetify.search.form_placeholder`)"
              v-model="model.search.searchTerm"
            >
            </fw-input>
          </div>
        </fw-col>
      </fw-row>
      <fw-row class="mt-0">
        <fw-col class="px-1 pt-5">
          <div>
            <fw-paper
              height="68px"
              v-for="language in model.language.filteredLangList"
              :key="language"
              class="d-flex align-center cursor-pointer px-5"
              :class="{
                't-color-primary': language.key === currentLanguage,
                'bg-border': language.key === currentLanguage,
              }"
              @click="changeLanguage(language.key)"
            >
              <span class="text-body-xl font-weight-large">
                {{ language.name }}
              </span>
            </fw-paper>
            <div v-if="model.language.filteredLangList.length === 0">
              <div class="text-center mt-5">
                <span> {{ $tt("$vuetify.search.result") }}</span>
              </div>
            </div>
          </div>
        </fw-col>
      </fw-row>
    </fw-dialog>
  </div>
</template>

<script>
import { useFw } from "@/fw";
import { defineComponent, reactive, toRefs, watch, computed } from "vue";
import { obj_lang__data } from "@/fw_config/i18n";

let obj_lang = {
  search: {
    language_title: {
      en: "Language",
      ko: "언어",
    },
    form_placeholder: {
      en: "Search language",
      ko: "언어 검색",
    },
    result: {
      en: "No results found.",
      ko: "검색 결과가 없습니다.",
    },
  },
};

export default defineComponent({
  name: "CompChangeLanguagePopup",

  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },

  beforeMount() {
    this.$fw.lang.add(obj_lang);
  },

  setup(props) {
    const { display, lang } = useFw();
    const currentLanguage = computed(() => lang.locale_current());
    const state = reactive({
      ui: {
        modal: {
          isActive: false,
        },
      },
      model: {
        search: {
          searchTerm: "",
        },
        language: {
          languageList: obj_lang__data.language_list,
          filteredLangList: [],
        },
      },
    });

    const computeModalWidthSize = computed(() => {
      return display.xs ? "100%" : "375px";
    });

    const computeModalHeightSize = computed(() => {
      return display.xs ? "100%" : "768px";
    });

    const computeModalRadius = computed(() => {
      return display.xs ? "" : "rounded-md";
    });

    const closeModal = () => {
      state.ui.modal.isActive = false;
    };

    const changeLanguage = (language) => {
      lang.locale_change(language);
      localStorage.setItem("selectedLang", language);

      state.ui.modal.isActive = false;
    };

    watch(
      () => props.showModal,
      (newVal) => {
        state.ui.modal.isActive = !state.ui.modal.isActive;
        state.model.search.searchTerm = "";
      }
    );

    watch(
      () => state.model.search.searchTerm,
      (newVal) => {
        state.model.language.filteredLangList =
          state.model.language.languageList.filter(
            (language) =>
              language.name.toLowerCase().includes(newVal.toLowerCase()) ||
              language.key.toLowerCase().includes(newVal.toLowerCase())
          );
      },
      { immediate: true }
    );

    return {
      ...toRefs(state),
      closeModal,
      changeLanguage,
      currentLanguage,
      computeModalWidthSize,
      computeModalHeightSize,
      computeModalRadius,
    };
  },
});
</script>

<style lang="scss">
.fw-dialog-content {
  margin: 0 !important;
  max-height: 100% !important;
  border-radius: 0;
}

.fw-input {
  height: 44px;
  .inp-wrapper {
    .inp {
      font-size: 16px;
      border: 1px solid var(--fw-theme-support-input-border) !important;
      border-radius: 8px;
    }
  }
}
.inp::placeholder {
  font-size: 16px;
  font-weight: 400;
}

.input-container {
  background-image: url("@/assets/img/appbar_language_search_icon.png");
  background-position: calc(100% - 12px) center;
  background-repeat: no-repeat;
  background-size: 20px;
}
</style>
