<template>
	<qrcode-vue
		:value="value"
		:background="background"
		:foreground="foreground"
		:class="className"
		:size="size"
		level="H"
	/>
</template>

<script>
	import QrcodeVue from "qrcode.vue";
	
	export default {
		components: {
			QrcodeVue,
		},
		props: {
			value: {
				required: true,
				type: String,
			},
			size: {
				required: false,
				type: Number,
				default: 200,
			},
			className: { 
				required: false,
				type: String,
			},
			background: { 
				required: false,
				type: String,
			},
			foreground: { 
				required: false,
				type: String,
			}
		}
	}
</script>
