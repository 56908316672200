import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slider = _resolveComponent("Slider")!

  return (_openBlock(), _createBlock(_component_Slider, {
    modelValue: _ctx.computedModelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedModelValue) = $event)),
    class: "fw-slider mx-auto",
    classes: _ctx.classes,
    min: _ctx.min,
    max: _ctx.max,
    step: _ctx.step,
    format: _ctx.tooltipFormatInfo,
    disabled: _ctx.disabled,
    showTooltip: _ctx.alwaysTooltip ? 'always':'drag'
  }, null, 8, ["modelValue", "classes", "min", "max", "step", "format", "disabled", "showTooltip"]))
}