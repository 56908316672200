<template>
  <button class="fw-btn btn" :class="classes" :style="styles" v-ripple="ripple" :disabled="disabled" @touchend="$emit('touch')">
    <slot></slot>
  </button>
</template>

<script>

import {
  makeBackgroundColorProps,
  useBackgroundColor,
} from "@/fw/composables/backgroundable";
import { defineComponent, computed } from "vue";
import { makeDimensionProps, useDimension } from "@/fw/composables/dimensions";
import { makeShadowProps, useShadow } from "@/fw/composables/boxShadows";
import { makeRoundedProps, useRounded } from "@/fw/composables/rounded";
import { makeColorableProps  } from "@/fw/composables/colorable";

import { propValidator } from "@/fw/js/util";

export default defineComponent({
  name: "FwBtn",

  props: {
    size: {
      type: String,
      ...propValidator("size", ["x-small", "small", "normal", "large", "x-large"]),
    },

    ripple: {
      type: Boolean, 
      default: true,
    },

    block: {
      type: Boolean,
      default: false,
    },

    icon: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    outlined: {
      type: Boolean,
      default: false,
    },

    ...makeDimensionProps(),
    ...makeShadowProps(),
    ...makeRoundedProps(),
    ...makeColorableProps(),
    ...makeBackgroundColorProps(),
  },

  setup(props, { emit }) {
    const { dimensionStyles } = useDimension(props);
    const { shadowClasses } = useShadow(props);
    const { backgroundClasses } = useBackgroundColor(props);
    const { roundedClasses } = useRounded(props);

    const classes = computed(() => {
      return [
        shadowClasses.value,
        roundedClasses.value,
        props.color? `btn-${props.color}` : "",
        props.size? `size-${props.size}` : "",
        props.icon? `btn-icon` : "",
        props.block? "w-100" : "",
        props.outlined? "outlined" : "",
        backgroundClasses.value,
      ];
    });

    const styles = computed(() => {
      return [dimensionStyles.value];
    });

    function onClick() {
      emit("click");
    }

    return {
      classes,
      styles,
      onClick,
    };
  },
});
</script>
