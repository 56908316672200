import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderSlot as _renderSlot, Transition as _Transition, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "fw-checkbox-card__label l-row align-center no-wrap pa-3" }
const _hoisted_2 = { class: "fw-checkbox-card__content" }
const _hoisted_3 = {
  key: 0,
  class: "fw-checkbox-card__valid_detail px-3 py-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fw_icon = _resolveComponent("fw-icon")!
  const _component_fw_paper = _resolveComponent("fw-paper")!
  const _component_fw_card = _resolveComponent("fw-card")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Field, {
      type: "checkbox",
      modelValue: _ctx.inputValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputValue) = $event)),
      id: _ctx.name,
      name: _ctx.name,
      value: true,
      rules: _ctx.rulesExecutor,
      "unchecked-value": false
    }, {
      default: _withCtx(({ field }) => [
        _createVNode(_component_fw_card, {
          class: _normalizeClass(["fw-checkbox-card rounded-md no-bg pa-0", _ctx.classes]),
          ripple: _ctx.disabled ? false : _ctx.ripple
        }, {
          default: _withCtx(() => [
            _createElementVNode("label", _hoisted_1, [
              _createVNode(_component_fw_paper, {
                width: _ctx.checkboxContainerSize,
                height: _ctx.checkboxContainerSize,
                class: _normalizeClass(["fw-checkbox-card__checkbox d-flex justify-center align-center no-bg pa-0", _ctx.reverse ? 'ml-3' : 'mr-3'])
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_fw_icon, {
                    icon: _ctx.iconCheckbox,
                    size: _ctx.iconSize
                  }, null, 8, ["icon", "size"]), [
                    [_vShow, _ctx.iconIsShow || _ctx.onIconNone || _ctx.offIconNone]
                  ]),
                  _createElementVNode("input", _mergeProps({
                    type: "checkbox",
                    class: ["checkbox fw-checkbox__core", _ctx.checkboxClasses],
                    value: true
                  }, field, {
                    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)), ["prevent"]))
                  }), null, 16),
                  _withDirectives(_createElementVNode("em", {
                    class: _normalizeClass(["fw-checkbox-card__em", _ctx.modelValue ? `bg-${_ctx.color} border-${_ctx.color}` : ''])
                  }, null, 2), [
                    [_vShow, !_ctx.iconIsShow]
                  ])
                ]),
                _: 2
              }, 1032, ["width", "height", "class"]),
              _createElementVNode("span", _hoisted_2, [
                _createElementVNode("span", {
                  class: _normalizeClass(["fw-checkbox-card__content", _ctx.labelClasses])
                }, [
                  _renderSlot(_ctx.$slots, "default")
                ], 2)
              ])
            ])
          ]),
          _: 2
        }, 1032, ["class", "ripple"]),
        (_ctx.showValid)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_Transition, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ErrorMessage, {
                    name: _ctx.name,
                    class: "d-block t-color-error"
                  }, null, 8, ["name"])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["modelValue", "id", "name", "rules"])
  ]))
}