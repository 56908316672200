//---------------------------------------------------------------------------------------------------//
// logic

import { NavigationGuardNext, RouteLocation } from "vue-router";

/*
        // config 예제
        {
			const arr_route = [
				{
					(must)name: "home",
					(must)path: "/",
					(must)component: () => import("filepath of .vue"),
				},
				{
					(must)name: "example_page",
					(must)path: "/example/page/:param_page_num",
					(must)component: () => import("@/view/page.vue"),
				},
				...,

				// children example
				{
					name: "parent_page",
					path: "/parent",
					component: () => import("@/view/parent.vue"),
					children: [
						{
							name: "child_1"
							path: "child_1" // --> "/child": x, "child": o
							compontent: () => import("@/view/child_1.vue")
						}
					]
				},
			];

            let fn_before_each = (to, from, next) => {
                console.log("global beforeEach","|","to -->",to,"|","from -->",from);
                next();
            }
        }

    // 사용법
    {
        //  현재 라우트를 변경
        {
            $router.push({
                name:"example_page",
                param: {
                    param_page_num: "1",
                    ...,
                },
                query: {
                    query_string_name: "query_string_value",
                    ...,
                }
            })
        }

        //  history 객체에 남기지 않고 라우트를 변경
        {
            $router.replace(.... 상동)
        }

        // 앞 또는 뒤 위치로 이동할 수 있음
        {
            $router.go(1); // 양수 페이지 만큼 히스토리 최근으로
            $router.go(-1); // 음수 페이지 만큼 히스토리 이전으로
        }

        // 라우트 대상 컴포넌트에서 이동 감지 이벤트
        {
            const SiteList = { 
                template: '<div>Test</div>',
                beforeRouteUpdate (to, from, next) {
                    alert('Route will be updated.')
                    next()
                }
            }
        }
    }
*/

export const arr_route = [
  { path: "/", redirect: "/home" },
  {
    name: "Main",
    path: "/home",
    component: () => import("@/views/PageHome.vue"),
  },
  {
    name: "Products",
    path: "/products",
    component: () => import("@/views/PageProducts.vue"),
  },
  {
    name: "Business",
    path: "/business",
    component: () => import("@/views/PageBusinessMain.vue"),
    children: [
      {
        name: "main",
        path: "",
        component: () => import("@/views/PageBusiness.vue"),
      },
      {
        name: "DetailDefi",
        path: "defi",
        component: () => import("@/views/PageDefi.vue"),
      },
      {
        name: "DetailFintech",
        path: "fintech-software",
        component: () => import("@/views/PageFintech.vue"),
      },
      {
        name: "DetailNft",
        path: "nft-game",
        component: () => import("@/views/PageNft.vue"),
      },
      {
        name: "DetailExchange",
        path: "exchange",
        component: () => import("@/views/PageExchange.vue"),
      },
      {
        name: "DetailPayment",
        path: "payment",
        component: () => import("@/views/PagePayment.vue"),
      },
      {
        name: "DetailWallet",
        path: "wallet",
        component: () => import("@/views/PageWallet.vue"),
      },
    ]
  },
  {
    name: 'WhyBuyglobal',
    path: "/why-buyglobal",
    component: () => import("@/views/PageWhyBuyglobal.vue")
  },
  {
    name: 'Support',
    path: "/support",
    component: () => import("@/views/PageSupport.vue")
  },

];

export const fn_before_each = (
  _to: RouteLocation,
  _from: RouteLocation,
  _next: NavigationGuardNext
) => {
  _next();
};

// let fn_scroll_behavior = (_to:RouteLocation, _from:RouteLocation, _saved_scroll_position:ScrollBehavior) => {
//   if (_saved_scroll_position) {
//     // return _saved_scroll_position; // 뒤로가기, 앞으로가기 클릭 시 페이지 이동 후 이전 스크롤 위치로 이동
//   } else {
//     return {
//       x: 0,
//       y: 0,
//       behavior: "smooth",
//     };
//   }
// // };
// ---------------------------------------------------------------------------------------------------//
// export
