import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fw_card = _resolveComponent("fw-card")!

  return (_openBlock(), _createBlock(_component_fw_card, {
    class: _normalizeClass(["fw-text-card no-bg", _ctx.classes]),
    style: _normalizeStyle(_ctx.styles),
    ripple: _ctx.ripple,
    onClick: _ctx.onClick
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createElementVNode("span", {
          class: _normalizeClass(["fw-text-card__value py-0 px-2", _ctx.contentClass])
        }, _toDisplayString(_ctx.value), 3)
      ])
    ]),
    _: 3
  }, 8, ["class", "style", "ripple", "onClick"]))
}