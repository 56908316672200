import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "fw-slide",
  style: {"position":"relative","display":"flex"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fw_icon = _resolveComponent("fw-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.button && _ctx.hasPrev)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["fw-slide-btn fw-slide-btn-prev", { 'fw-slide-btn-between': _ctx.buttonBetween }]),
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.prev && _ctx.prev(...args)), ["stop"])),
          role: "button"
        }, [
          _renderSlot(_ctx.$slots, "btn-prev", {}, () => [
            _createVNode(_component_fw_icon, {
              icon: "chevron-left",
              color: "secondary"
            })
          ])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.button && _ctx.hasNext)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["fw-slide-btn fw-slide-btn-next", { 'fw-slide-btn-between': _ctx.buttonBetween }]),
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)), ["stop"])),
          role: "button"
        }, [
          _renderSlot(_ctx.$slots, "btn-next", {}, () => [
            _createVNode(_component_fw_icon, {
              icon: "chevron-right",
              color: "secondary"
            })
          ])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["fw-slide-container", {
        'fw-slide-responsive': _ctx.responsive,
        'fw-slide-scroll': _ctx.scroll,
        'fw-slide-snap-start': _ctx.snap === 'start',
        'fw-slide-snap-center': _ctx.snap === 'center',
        'fw-slide-snap-end': _ctx.snap === 'end',
      }]),
      ref: "container",
      onScrollPassive: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onScroll && _ctx.onScroll(...args)))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 34)
  ]))
}