
import { defineComponent, computed } from "vue";

import { makeBorderProps, useBorder } from "@/fw/composables/border";
import { makeDimensionProps, useDimension } from "@/fw/composables/dimensions";
import { makeShadowProps, useShadow } from "@/fw/composables/boxShadows";
import { makeRoundedProps, useRounded } from "@/fw/composables/rounded";
import { propValidator } from "@/fw/js/util";
import { FwCard } from "@/fw/components";

export default defineComponent({
  name: "FwTextCard",
  components: {
    FwCard,
  },
  props: {
    value: {},
    ripple: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      ...propValidator("size", ["small", "normal", "large", "x-large"]),
      default: "normal",
    },
    color: {
      type: String,
      default: "normal",
    },
    opacity: {
      type: String,
      default: "normal",
      ...propValidator("opacity", ["x-small", "small", "normal", "large", "x-large"]),
    },
    contentClass: {
      type: String,
      default: "",
    },
    ...makeBorderProps(),
    ...makeDimensionProps(),
    ...makeShadowProps(),
    ...makeRoundedProps(),
  },

  emits: ["click"],

  setup(props, { emit }) {
    const { borderClasses } = useBorder(props);
    const { dimensionStyles } = useDimension(props);
    const { shadowClasses } = useShadow(props);
    const { roundedClasses } = useRounded(props);

    const classes = computed(() => {
      return [
        borderClasses.value,
        shadowClasses.value,
        roundedClasses.value,
        `t-color-${props.color}`,
        `fw-text-card__opacity--${props.opacity}`,
        props.value ? `chip-size-${props.size} t-size-${props.size} pa-0 d-flex align-center justify-center`:'',
      ];
    });

    const styles = computed(() => {
      return [
        dimensionStyles.value,
    ];
    });

    function onClick(_event: Event): void {
      emit("click", _event);
    }

    return {
      classes,
      styles,
      onClick,
    };
  },
});
