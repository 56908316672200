import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { arr_route, fn_before_each } from "@/fw_config/router";

const routes: Array<RouteRecordRaw> = arr_route;

const c_router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    return { top: 0 };
  }
});

c_router.beforeEach(fn_before_each);

export { c_router };
