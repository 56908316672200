
import { Field, ErrorMessage, GenericValidateFunction } from "vee-validate";
import { defineComponent, computed, reactive, toRefs, watch, onMounted } from "vue";
import { v4 } from "uuid";
import { makeBorderProps, useBorder } from "@/fw/composables/border";
import { makeShadowProps, useShadow } from "@/fw/composables/boxShadows";
import { makeRoundedProps, useRounded } from "@/fw/composables/rounded";
import { propValidator } from "@/fw/js/util";
export default defineComponent({
  name: "FwCheckboxCard",
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    modelValue: {       // v-model(필수)
      type: [Array, Boolean],
      default: false,
    },     
    name: {             // 이름
      type: String,
      default: () => v4(),
    },
    rules: {            // 유효성검사 함수
      type: Function,
      default: () => true,
    },
    showValid: {       // 유효성검사 알림 메시지 보이기
      type: Boolean,
      default: false,
    },
    ripple: {           // ripple
      type: Boolean,
      default: false,
    },
    size: {             // checkbox, font 사이즈
      type: String,
      ...propValidator("size", ["small", "normal", "large"]),
      default: "normal",
    },
    color: {            // border, icon, font 색깔
      type: String,
      default: "primary",
    },
    onIcon: {           // active시 icon
      type: String,
      default: "check",
    },
    offIcon: {          // inactive시 icon
      type: String,
      default: "checkbox-blank-outline",
    },
    filled: {           // active시 배경색 유무
      type: Boolean,
      default: false,
    },
    reverse: {          // 체크박스 위치 반전
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ...makeBorderProps(),
    ...makeShadowProps(),
    ...makeRoundedProps(),
  },

  emits: ["click", "update:modelValue"],

  setup(props, { emit }) {
    const stateValid = reactive({
      inputValue: false,
      isValid: true,
    });

    function onClick(): void {
      if(props.disabled){
        return ;
      }

      stateValid.inputValue = !props.modelValue
      emit("update:modelValue", !props.modelValue);
    }

    // ==========================================
    // rules 실행
    const rulesExecutor = (): GenericValidateFunction => {
        return props.rules(stateValid.inputValue);
    }

    // ==========================================
    // class & style
    const { borderClasses } = useBorder(props);
    const { shadowClasses } = useShadow(props);
    const { roundedClasses } = useRounded(props);

    const classes = computed(() => {
      return [
        borderClasses.value,
        shadowClasses.value,
        roundedClasses.value,
        props.reverse ? "reverse" : "",
        props.filled && props.modelValue ? "filled" : "",
        props.modelValue ? `fw-checkbox-card__checked t-color-${props.color} border-${props.color}` : "t-color-disabled border-disabled",
      ];
    });

    const labelClasses = computed(() => {
      return [
        props.modelValue ? `t-color-${props.color}` : "t-color-normal",
        `t-size-${props.size}`,
      ];
    });

    const checkboxClasses = computed(() => {
      return [`size-${props.size}`];
    });

    const checkboxContainerSize = computed(() => {
      switch (props.size) {
        case "large":
          return 30;
        case "small":
          return 15;
        default:
          return 20;
      }
    });

    const onIconNone = computed(() => {
      return props.onIcon !== "none" && props.modelValue;
    });

    const offIconNone = computed(() => {
      return props.offIcon !== "none" && !props.modelValue;
    });

    const iconIsShow = computed(() => {
      return (
        (props.onIcon && props.modelValue) ||
        (props.offIcon && !props.modelValue)
      );
    });

    const iconCheckbox = computed(() => {
      return props.modelValue ? props.onIcon : props.offIcon;
    });

    const iconSize = computed(() => {
      switch (props.size) {
        case "large":
          return 30 * 1.4;
        case "small":
          return 15 * 1.4;
        default:
          return 20 * 1.4;
      }
    });

    watch(
      () => stateValid.inputValue,
      (_val) => {
        emit("update:modelValue", _val)
      }
    )

    onMounted(() => {
      stateValid.inputValue = props.modelValue as boolean;
    })

    return {
      Field,
      ErrorMessage,
      rulesExecutor,
      ...toRefs(stateValid),

      classes,
      onClick,
      
      labelClasses,

      iconCheckbox,
      checkboxClasses,
      checkboxContainerSize,
      iconIsShow,
      onIconNone,
      offIconNone,
      iconSize,
    };
  },
});
