<template>
  <img 
    :src="src"
    :style="styles"
    :alt="alt"
    @click="onClick"
  />
</template>

<script>
import { defineComponent, computed } from "vue";
import { makeDimensionProps, useDimension } from "@/fw/composables/dimensions";

export default defineComponent({
  name: "FwImg",

  props: {
    src: {
      type: String,
      default: "",
      required: true,
    },
    ripple: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: String, 
      default: "",
    },
    aspectRatio: {
      type: [String, Number],
      default: null,
    },
    objectFit: {
      type: String,
      default: "contain",
    },
    disabled: Boolean,
    ...makeDimensionProps(),
  },

  emits: ["click"],

  setup(props, { emit }) {
    const { dimensionStyles } = useDimension(props);

    const styles = computed(() => {
      return [
        dimensionStyles.value,
        {
          aspectRatio: props.aspectRatio,
          objectFit: props.objectFit,
        }
      ];
    });

    const onClick = ($event) => {
      emit("click", $event);
    };

    return {
      styles,
      onClick,
    };
  },
});
</script>

<style></style>
