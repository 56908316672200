import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FwDialogContent = _resolveComponent("FwDialogContent")!

  return (_openBlock(), _createBlock(_Transition, { name: _ctx.transition }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        ref: "frameElement",
        class: _normalizeClass(_ctx.classes),
        style: _normalizeStyle(_ctx.styles)
      }, [
        _createVNode(_component_FwDialogContent, {
          class: _normalizeClass(_ctx.contentClass),
          "max-width": _ctx.maxWidth,
          width: _ctx.width,
          height: _ctx.height,
          scrollable: _ctx.scrollable,
          depressed: _ctx.depressed,
          fullscreen: _ctx.fullscreen,
          background: _ctx.background,
          "border-radius": _ctx.borderRadius
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["class", "max-width", "width", "height", "scrollable", "depressed", "fullscreen", "background", "border-radius"])
      ], 6), [
        [_vShow, _ctx.isActive]
      ])
    ]),
    _: 3
  }, 8, ["name"]))
}