const hasScrollbar = (el?: Element) => {
  if (!el || el.nodeType !== Node.ELEMENT_NODE) return false

  const style = window.getComputedStyle(el)
  return ['auto', 'scroll'].includes(style.overflowY) && el.scrollHeight > el.clientHeight
}

const shouldScroll = (el: Element, delta: number) => {
  if (el.scrollTop === 0 && delta < 0) return true
  return el.scrollTop + el.clientHeight === el.scrollHeight && delta > 0
}

export const noScrollableParent = (event: WheelEvent, content: Element | undefined): boolean => {
  const path = event.composedPath()
  const delta = event.deltaY

  for (let index = 0; index < path.length; index++) {
    const el = path[index]

    if (el === document) return true
    if (el === document.documentElement) return true
    if (el === content) return true

    if (hasScrollbar(el as Element)) return shouldScroll(el as Element, delta)
  }

  return true
}

export const getScrollbarWidth = (): number => {
  const container = document.createElement('div')
  container.style.visibility = 'hidden'
  container.style.overflow = 'scroll'
  const inner = document.createElement('div')

  container.appendChild(inner)
  document.body.appendChild(container)
  const scrollbarWidth = container.offsetWidth - inner.offsetWidth
  document.body.removeChild(container)

  return scrollbarWidth
}
