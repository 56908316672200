<template>
  <div class="l-spacer"></div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FwSpacer",
});
</script>
