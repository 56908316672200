<template>
  <div class="fw-tabs" :class="classes" :style="styles">
    <fw-slide :displacement="0.5" :button-between="false" :style="styles">
      <slot></slot>
    </fw-slide>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted } from "vue";
import FwSlide from "../slide/fw__slide.vue";
import { makeDimensionProps, useDimension } from "@/fw/composables/dimensions";
import { makeRoundedProps, useRounded } from "@/fw/composables/rounded";

export default defineComponent({
  components: {
    FwSlide,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    activeClass: {
      type: String,
      default: "",
    },
    noBottomLine: {
      type: Boolean,
      default: false,
    },
    ...makeDimensionProps(),
    rounded: {
      type: [String, Boolean],
      default: false,
    }
  },
  computed: {
    selected: {
      get() {
        return this.modelValue;
      },
      set(_value) {
        this.$emit("update:modelValue", _value);
      },
    },
  },

  mounted() {
    if (this.modelValue == "" || typeof this.modelValue == "undefined") {
      // this.selected = this.$slots.default()[0].children[0].props.value;
    }
    this.$emit("loaded", this.selected);
  },

  methods: {
    onClickTab(_value) {
      this.selected = _value;
      this.$emit("change", this.selected);
    },
  },

  setup(props, { emit }) {
    const { dimensionStyles } = useDimension(props);
    const { roundedClasses } = useRounded(props);

    const classes = computed(() => {
      return [
        roundedClasses.value,
        {
          "fw-tab--full-width": props.fullWidth,
          "fw-tab--no-bottom-line": props.noBottomLine,
        },
      ];
    });

    const styles = computed(() => {
      return [dimensionStyles.value];
    });

    return {
      classes,
      styles,
    };
  },
});
</script>

<style lang="scss">
@import "./fw__tabs.scss";
</style>
