<template>
  <span class="cursor-default">
    <slot name="prepend" />

    {{ countNumber }}

    <slot name="append" />
  </span>
</template>

<script>
import Big from "big.js";
import { defineComponent, reactive, computed, onMounted } from "vue";
import { useFw } from "@/fw";

export default defineComponent({
  name: "FwCount",

  props: {
    value: { // v-model
      type: [String, Number],
      default: 0,
    },

    countTime: { // 값이 한 번 바뀌는 시간
      type: [String, Number],
      default: 50,
    },

    toFixed: { // 소수점 자릿수
      type: [String, Number],
      default: 0,
    },

    putComma: { // 콤마 여부
      type: Boolean,
      default: false,
    },
  },

  setup(props, ){
    const { func } = useFw();

    const state = reactive({
      model: {
        count: {
          interval: null,
          step: 0,
          now: 0,
        },
      },
    })

    const api = {
      refresh: () => {
        count.start();
      },
      
      clear: () => {
        count.end();
      }
    }

    const count = {
      start: () => {
        if (state.model.count.interval) {
          count.end();
        }
        state.model.count.now = new Big(props.value);
        state.model.count.interval = setInterval(
          count.numAdd,
          intervalTimeGet.value
        );
      },

      numAdd: () => {
        if (state.model.count.now.lt(1 / 10 ** toFixedGet.value)) {
          count.end();
          return;
        }

        state.model.count.step = state.model.count.now.times(0.1);
        state.model.count.now = state.model.count.now.minus(state.model.count.step);
      },

      end: () => {
        clearInterval(state.model.count.interval);
        state.model.count.now = 0;
      },
    }

    const countNumber = computed(() => {
      if (isNaN(props.value * 1)) {
          console.error("value is NaN.");
          return 0;
        }

        Big.RM = 3;
        let showing_number = new Big(props.value * 1 - state.model.count.now * 1);

        if(props.putComma){
            return func.num.comma.insert(showing_number.toFixed(toFixedGet.value));

        } else {
            return showing_number.toFixed(toFixedGet.value);
        }
      });

    const toFixedGet = computed(() => {
      if (isNaN(props.toFixed)) {
        console.error("to-fixed is NaN.");
        return 0;
      }

      if (props.toFixed < 0 || props.toFixed > 100) {
        console.error("to-fixed must be between 0 and 100.");
        return 0;
      }

      return props.toFixed * 1;
    });

    const intervalTimeGet = computed(() => {
      if (!props.countTime) {
        console.error("count-time must be Positive number.");
        return 50;
      }

      if (isNaN(props.countTime)) {
        console.error("count-time is NaN.");
        return 50;
      }

      if (props.countTime < 0) {
        console.error("count-time is Nagative.");
        return Math.abs(props.countTime);
      }

      return props.countTime;
    });

    onMounted(() => {
      count.start();
    })

    return {
      countNumber,
      api,
    }
  },
  },
);
</script>

<style lang="scss" scoped>
.cursor-default {
  cursor: default;
}
</style>
