// Utilities
import { computed, isRef } from "vue";
import { propsFactory } from "@/fw/js/util";

// Types
import type { Ref } from "vue";

type BackgroundColorValue = string;

export interface BackgroundColorProps {
  background: BackgroundColorValue;
}

type BackgroundColorData = {
  backgroundClasses: Ref<string[]>;
};

// Composables
export const makeBackgroundColorProps = propsFactory(
  {
    background: {
      type: String,
      default: "",
    },
  },
  "background"
);

export function useBackgroundColor(props: BackgroundColorProps | Ref<BackgroundColorValue>): BackgroundColorData {
  const backgroundClasses = computed(() => {
    const color = isRef(props) ? props.value : props.background;
    const classes: string[] = [];

    if( !color) {
      return classes;
    }
    
    classes.push(`bg-${color}`);

    return classes;
  });

  return { backgroundClasses };
}
