import { theme as themeConfig } from "@/fw_config/theme";
import { mergeDeep } from "@/fw/js/util";

const themeLight = themeConfig.light; 
const themeDark = themeConfig.dark; 

export const theme = {
  light: mergeDeep({
    primary: "#007afa",
    secondary: "#999",
    info: "#5C8BDB",
    success: "#28a745",
    error: "#f6465d",
    warning: "#f5b11c",
    disabled: "#afafaf",
    "layer0": "#E5E5E5",
    "layer1": "#FEFEFE",
    "layer2": "#F9FAFC",
    "layer3": "#f3f4f6",
    "border": "#eef0f4",
    "border-lighter": "#EEF0F4",
    "font": "#444e5a",
    "font-contrast": "#fff",
    "font-button": "#444e5a",
    "font-on-bg": "#fff",
    "trade-buy": "#0ecb81",
    "trade-rise": "#0ecb81",
    "trade-sell": "#f6465d",
    "trade-fall": "#f6465d",
    "form": "transparent",
    "button": "#eef0f4",
    "checkbox": "#CED2D6",
    "normal": "#444e5a",
    "table-header": "#f1f3f7",
    "table-tr-active": "#f1f7fe",
    "card-shadow": "0, 0, 0",
  }, themeLight),
  dark: mergeDeep({
    primary: "#096EFE",
    secondary: "#909498",
    info: "#5C8BDB",
    success: "#28a745",
    error: "#f6465d",
    warning: "#f5b11c",
    disabled: "#afafaf",
    "layer0": "#000000",
    "layer1": "#18171c",
    "layer2": "#2d3341",
    "layer3": "#3d4355",
    "border": "#32363E",
    "border-lighter": "#32363E",
    "font": "#fff",
    "font-contrast": "#444e5a",
    "font-button": "#444e5a",
    "font-on-bg": "#fff",
    "trade-buy": "#0ecb81",
    "trade-rise": "#0ecb81",
    "trade-sell": "#f6465d",
    "trade-fall": "#f6465d",
    "form": "transparent",
    "button": "#eef0f4",
    "checkbox": "#CED2D6",
    "normal": "#444e5a",
    "table-header": "#f1f3f7",
    "table-tr-active": "#f1f7fe",
    "card-shadow": "0, 0, 0",
  }, themeDark),
};