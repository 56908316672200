<template>
  <fw-paper class="fw-main">
    <slot></slot>
  </fw-paper>
</template>

<script>
import { ref, nextTick, getCurrentInstance, computed, onBeforeMount, reactive, watch } from "vue";
import { FwPaper } from "@/fw/components";
import { useFw } from "@/fw";
import { useRoute } from "vue-router";

export default {
  name: "FwMain",
  components: {
    FwPaper,
  },
  setup() {
    const vm = getCurrentInstance();
    const fw = useFw();
    const route = useRoute();

    const contentHeight = ref(null);

    onBeforeMount(() => {
      vm.appContext.config.globalProperties.app = reactive({
        header: {
          height: 0,
        },
      });
    });

    function initContentHeight() {
      setTimeout(() => {
        let app = vm.appContext.config.globalProperties.app;
        let appHeight = document.getElementById("app").clientHeight;
        let display = fw.display;
        // contentHeight.value = appHeight - app.header.height;
      }, 1000);
    }

    watch(
      (route) => {
        nextTick(() => {
          initContentHeight();
        });
      },
      { immediate: true },
      { deep: true }
    );

    return {
      contentHeight,
    };
  },
};
</script>

<style lang="scss">
@import "./fw__main.scss";
</style>
