
import { computed, defineComponent, nextTick, ref, watch } from "vue";

export default defineComponent({
  name: "FwOverlay",
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    zIndex: {
      type: Number,
      default: 10,
    },

    background: {
      type: [Boolean, String],
      default: true,
    },

    local: {
      type: Boolean,
    },

    clickOnClose: {
      type: Boolean,
      default: true,
    },
  },

  emits: ["click", "update:modelValue"],

  setup(props, { emit }) {
    const styles = computed(() => ({
      zIndex: props.zIndex - 1,
    }));

    const activeProxy = ref(props.modelValue);
    const deactivating = ref(false);
    watch(
      () => props.modelValue,
      (value) => {
        deactivating.value = !value;

        // immediate activation
        if (value) {
          activeProxy.value = true;
          return;
        }

        // delayed activation
        nextTick(() => {
          activeProxy.value = false;
        });
      }
    );

    const classes = computed(() => [
      "fw-overlay",
      {
        "fw-overlay--active": !deactivating.value,
        "fw-overlay--local": props.local,
        "fw-overlay--transparent": !props.background,
      },
    ]);

    const onClick = () => {
      if (props.clickOnClose) {
        emit("update:modelValue", false);
      }
      emit("click");
    };

    function close() {
      emit("update:modelValue", false);
    }

    return {
      activeProxy,
      styles,
      classes,
      onClick,
      close,
    };
  },
});
