// Utilities
import { computed } from "vue";
import { convertToUnit, propsFactory } from "@/fw/js/util";

// Types
export interface DimensionProps {
  height?: number | string;
  maxHeight?: number | string;
  maxWidth?: number | string;
  minHeight?: number | string;
  minWidth?: number | string;
  width?: number | string;
}

// Composables
export const makeDimensionProps = propsFactory(
  {
    height: [Number, String],
    maxHeight: [Number, String],
    maxWidth: [Number, String],
    minHeight: [Number, String],
    minWidth: [Number, String],
    width: [Number, String],
  },
  "dimension"
);

export function useDimension(props: DimensionProps) {
  const dimensionStyles = computed(() => ({
    height: convertToUnit(props.height),
    maxHeight: convertToUnit(props.maxHeight),
    maxWidth: convertToUnit(props.maxWidth),
    minHeight: convertToUnit(props.minHeight),
    minWidth: convertToUnit(props.minWidth),
    width: convertToUnit(props.width),
  }));

  return { dimensionStyles };
}
