import C_Tooltip from "./components/__tooltip.js";
import "./fw__tooltip.scss"; 

const DEF_BASE_CLASS = "fw-tooltip";
const DEF_POSITIONS = ["auto", "top", "bottom", "left", "right"];
const DEF_SUB_POSITIONS = ["start", "end"];

export default {
  name: "tooltip",
  config: {},
  install(_c_vue, _options) {
    _c_vue.directive("tooltip", {
      created() {
        if (_options) {
          C_Tooltip.defaults(_options);
        }
      },
      mounted(_el, _binding, _vnode) {
        if (_options) {
          C_Tooltip.defaults(_options);
        }

        let options = filterBindings(_binding, _vnode);
        _el.tooltip = new C_Tooltip(_el, options);

        if (_binding.modifiers.notrigger && _binding.value.visible === true) {
          _el.tooltip.show();
        }

        if (_binding.value && _binding.value.visible === false) {
          _el.tooltip.disabled = true;
        }
      },
      updated(_el, _binding, _vnode, _old_vnode) {
        if (hasUpdated(_binding.value, _binding.oldValue)) {
          update(_el, _binding, _vnode, _old_vnode);
        }
      },
      unmounted(_el) {
        _el.tooltip.destroy();
      },
    });
  },
};

function hasUpdated(_value, _old_value) {
  let updated = false;

  if (typeof _value === "string" && _value !== _old_value) {
    updated = true;
  } else if (isObject(_value)) {
    Object.keys(_value).forEach((prop) => {
      if (_value[prop] !== _old_value[prop]) {
        updated = true;
      }
    });
  }
  return updated;
}

function filterBindings(_binding, _vnode) {
  const delay =
    !_binding.value || isNaN(_binding.value.delay)
      ? C_Tooltip._defaults.delay
      : _binding.value.delay;

  if (_binding.value.ref) {
    if (_vnode.context.$refs[_binding.value.ref]) {
      _binding.value.html = _vnode.context.$refs[_binding.value.ref];
    } else {
      console.error(`[C_Tooltip] no REF element [${_binding.value.ref}]`); // eslint-disable-line
    }
  }

  return {
    class: getClass(_binding),
    id: _binding.value ? _binding.value.id : null,
    html: _binding.value ? _binding.value.html : null,
    placement: getPlacement(_binding),
    title: getContent(_binding),
    triggers: getTriggers(_binding),
    fixIosSafari: _binding.modifiers.ios || false,
    offset:
      _binding.value && _binding.value.offset
        ? _binding.value.offset
        : C_Tooltip._defaults.offset,
    delay,
  };
}

function getPlacement({ modifiers, value }) {
  let MODS = Object.keys(modifiers);
  if (
    MODS.length === 0 &&
    isObject(value) &&
    typeof value.placement === "string"
  ) {
    MODS = value.placement.split(".");
  }
  let head = "bottom";
  let tail = null;
  for (let i = 0; i < MODS.length; i++) {
    const pos = MODS[i];
    if (DEF_POSITIONS.indexOf(pos) > -1) {
      head = pos;
    }
    if (DEF_SUB_POSITIONS.indexOf(pos) > -1) {
      tail = pos;
    }
  }
  // console.log((head && tail) ? `${head}-${tail}` : head);
  return head && tail ? `${head}-${tail}` : head;
}

function getTriggers({ modifiers }) {
  let trigger = [];
  if (modifiers.notrigger) {
    return trigger;
  } else if (modifiers.manual) {
    trigger.push("manual");
  } else {
    if (modifiers.click) {
      trigger.push("click");
    }

    if (modifiers.hover) {
      trigger.push("hover");
    }

    if (modifiers.focus) {
      trigger.push("focus");
    }

    if (trigger.length === 0) {
      trigger.push("hover", "focus");
    }
  }

  return trigger;
}

function isObject(_value) {
  return typeof _value === "object";
}

function isElement(_value) {
  return _value instanceof window.Element;
}

function getClass({ value }) {
  if (value === null) {
    return DEF_BASE_CLASS;
  } else if (isObject(value) && typeof value.class === "string") {
    return `${DEF_BASE_CLASS} ${value.class}`;
  } else if (C_Tooltip._defaults.class) {
    return `${DEF_BASE_CLASS} ${C_Tooltip._defaults.class}`;
  } else {
    return DEF_BASE_CLASS;
  }
}
function getContent({ value }, _vnode) {
  if (value !== null && isObject(value)) {
    if (value.content !== undefined) {
      return `${value.content}`;
    } else if (value.id && document.getElementById(value.id)) {
      return document.getElementById(value.id);
    } else if (value.html && document.getElementById(value.html)) {
      return document.getElementById(value.html);
    } else if (isElement(value.html)) {
      return value.html;
    } else if (value.ref && _vnode) {
      return _vnode.context.$refs[value.ref] || "";
    } else {
      return "";
    }
  } else {
    return `${value}`;
  }
}
function update(_el, binding, vnode) {
  if (typeof binding.value === "string") {
    _el.tooltip.content(binding.value);
  } else {
    if (
      binding.value &&
      binding.value.class &&
      binding.value.class.trim() !==
        _el.tooltip.options.class.replace(DEF_BASE_CLASS, "").trim()
    ) {
      _el.tooltip.class = `${DEF_BASE_CLASS} ${binding.value.class.trim()}`;
    }

    _el.tooltip.content(getContent(binding, vnode));

    if (
      !binding.modifiers.notrigger &&
      binding.value &&
      typeof binding.value.visible === "boolean"
    ) {
      _el.tooltip.disabled = !binding.value.visible;
      return;
    } else if (binding.modifiers.notrigger) {
      _el.tooltip.disabled = false;
      _el.tooltip.toggle(binding.value.visible);
      return;
    }

    const dir = vnode.data.directives[0];

    if (dir.oldValue.visible !== dir.value.visible) {
      if (!_el.tooltip.disabled) {
        _el.tooltip.toggle(dir.value.visible);
      }
    }
  }
}
