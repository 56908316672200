<template>
	<fw-paper 
		v-html="avatar__get"
		flat
		:width="size"
		:height="size"
		:class="classes"
		@click="$emit('click')"
	>
	</fw-paper>
</template>

<script>
import { defineComponent } from "vue";
import  FwPaper from "../paper/fw__paper.vue";
import { identicon } from "./generateAvatar";

export default defineComponent({
  components: {
    FwPaper,
  },

	props: {
		"value": {
			type: String,
			default: "",
		},

		"size": {
			type: [Number, String],
			default: 30,
		},

		"badge": {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			
		};
	},

	computed: {
		avatar__get() {
			return identicon(this.value);
		},

		classes() {
			if(this.badge){
				return "rounded-circle bg-layer2 shadow-sm";
			}

			return "";
		},
	},
});
</script>