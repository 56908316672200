// Utilities
import { computed, isRef } from "vue";
import { propsFactory, propValidator } from "@/fw/js/util";

// Types
export interface BorderProps {
  border?: boolean | number | string;
}

// Composables
export const makeBorderProps = propsFactory(
  {
    border: {
      type: [Boolean, Number, String],
      default: false,
    },
  },
  "border"
);

export function useBorder(props: BorderProps) {
  const borderClasses = computed(() => {
    let border = isRef(props) ? props.value : props.border;
    const classes: string[] = [];

    if (border == null) return classes;
    if (border == false) return classes;
    if (border == true) border = "normal";

    classes.push(`border-${border}`);

    return classes;
  });

  return { borderClasses };
}
