//---------------------------------------------------------------------------------------------------//
// import

//---------------------------------------------------------------------------------------------------//
// logic

/*
    let obj_language__trans_data = {
        category_1: {
            category_n: {
                trans_key_1: {
                    lang_code: 'transration text',
                    en: 'english arg_0({0}), arg_1({1})',
                    ko: '한글 인자_0({0}), 인자_1({1})',
                },
                trans_key_n: {
                    lang_code: 'transration text',
                    en: 'english arg_0({0}), arg_1({1})',
                    ko: '한글 인자_0({0}), 인자_1({1})',
                },
            },
        },
    };
*/

let s_lang__default = "en"; // en cn

let obj_lang__data = {
  language_list: [
    {
      name: "English",
      key: "en",
    },
    {
      name: "한국어",
      key: "ko",
    },
  ],

  language: {
    en: "English",
    ko: "中文",
  },
  confirm: {
    en: "Confirm",
    ko: "确定",
  },
  cancel: {
    en: "Cancel",
    ko: "取消",
  },
  close: {
    en: "Close",
    ko: "关闭",
  },
  noDataText: {
    en: "No data",
    ko: "No data",
  },
};

//---------------------------------------------------------------------------------------------------//
// export

export { s_lang__default, obj_lang__data };
