/*
	import import_module_name from "@/js/vuex/?????.js"
	const vuex_modules =  {
		MODULE_NAME: import_module_name,
		...
	}

	** usage
	this.$store.state.MODULE_NAME.some_state;
	this.$store.commit("MODULE_NAME/some_mutation");
	this.$store.getters["MODULE_NAME/get_something"];
	this.$store.dispatch("MODULE_NAME/do_something");
*/
import ExampleModule from "@/store/example_module";

const vuex_config = {
  ExampleModule: ExampleModule,
};

export { vuex_config };
