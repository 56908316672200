
  import { defineComponent, reactive, toRefs, computed, watch, onMounted, ref } from "vue";
  import { FwInput, FwIcon, FwDatepicker } from "@/fw/components";
  import { format } from "date-fns";
  import { makeBorderProps } from "@/fw/composables/border";
  import { makeDimensionProps } from "@/fw/composables/dimensions";
  import { makeShadowProps } from "@/fw/composables/boxShadows";
  import { makeRoundedProps } from "@/fw/composables/rounded";
  import { makeBackgroundColorProps } from "@/fw/composables/backgroundable";

  export default defineComponent({
    name: "FwDatepickerInput",

    components: {
      FwInput,
      FwIcon,
      FwDatepicker,
    },

    props: {
      modelValue: {
        type: [String, Array],
      },
        
      range: {
        // 기간 선택
        type: Boolean,
        default: false,
      },

      disabled: {
        // disabled
        type: Boolean,
        default: false,
      },

      untilToday: {
        // 오늘 이후는 선택 불가
        type: Boolean,
        default: false,
      },

      defaultPeriod: {
        // 기간 디폴트값 ( range )
        type: Number,
        default: 6,
      },

      color: {
        // 색 테마
        type: String,
        default: "primary",
      },

      label: {
        type: String,
        default: "",
      },

      ...makeBorderProps(),
      ...makeDimensionProps(),
      ...makeShadowProps(),
      ...makeRoundedProps(),
      ...makeBackgroundColorProps(),
    },

    setup(props, { emit }){
      const fw_datepicker_input = ref();
      const inputState = reactive({
        inputValue: "",
      });

      // 인풋 날짜형식 format - ex) 10 Nov, 22
      const dateFormatGet = (_date: Date | Date[]) => {
        if (!_date) {
          console.error("Please Select a date.");
          return "-";
        }

        if (!Array.isArray(_date)) {
          return format(_date, "dd MMM, yy");
        }

        if (!_date[1]) {
          return `${format(_date[0], "dd MMM, yy")} ~ ${format(_date[0], "dd MMM, yy")}`;
        } else {
          return `${format(_date[0], "dd MMM, yy")} ~ ${format(_date[1], "dd MMM, yy")}`;
        }
      };

      const onClickDatePickerInput = () => {
        emit("click");
      }

      const computedModelValue = computed({
        get() {
          return props.modelValue;
        },
        set(_value: any) {
          if (!Array.isArray(_value)) {
            return emit("update:modelValue", format(new Date(_value), "yyyy-MM-dd"));
          }

          if (!_value[1]) {
            return emit("update:modelValue", [format(new Date(_value[0]), "yyyy-MM-dd")]);
          } else {
            return emit("update:modelValue", [
              format(new Date(_value[0]), "yyyy-MM-dd"),
              format(new Date(_value[1]), "yyyy-MM-dd"),
            ]);
          }
        },
      });

      const onClickTest = () => {
        inputState.inputValue = dateFormatGet(new Date(computedModelValue.value));
      }

      const inputValueSet = (_value: string) => {
        fw_datepicker_input.value.inputValueSet(_value);
      }

      watch(computedModelValue, () => {
        if(!Array.isArray(computedModelValue.value)){
          inputValueSet(dateFormatGet(new Date(computedModelValue.value)));
          return;
        } 
        if(!computedModelValue.value[1]) {
          inputValueSet(dateFormatGet([new Date(computedModelValue.value[0])]));

        } else {
          inputValueSet(dateFormatGet([new Date(computedModelValue.value[0]), new Date(computedModelValue.value[1])]));
        }
      })

      onMounted(() => {
        fw_datepicker_input.value;
      })

      return {
        fw_datepicker_input,

        onClickDatePickerInput,
        computedModelValue,
        ...toRefs(inputState),
        onClickTest,
      }
    }
  });
